import React from 'react'
import { Col, Container, Row, Image, Card } from 'react-bootstrap'
import TestimonialObject from '../../models/testimonialObject'

const Testimonial = (props: {
    testimonial: TestimonialObject
}) => {

    return (
        <Card bg="light" className="mb-2">
            <Card.Header>{props.testimonial.username} says:</Card.Header>
            <Card.Body>
                <div className="mb-3">
                    <Image src="/star.png" width="24" />
                    <Image src="/star.png" width="24" />
                    <Image src="/star.png" width="24" />
                    <Image src="/star.png" width="24" />
                    <Image src="/star.png" width="24" />
                </div>
                <Card.Title>{props.testimonial.title} </Card.Title>
                <Card.Text>
                    {props.testimonial.content}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Testimonial