import React from 'react'

const Loading = () => {

    // The style for the loading overlay that spans across the full screen
    const loadingStyle = {
        position: "fixed" as "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        zIndex: 3,
        backgroundColor: "gray",
        opacity: "80%"
    }

    const loadingGif = {
        position: "fixed" as "fixed",
        top: "50%",
        left: "50%",
        marginTop: "-25px",
        marginLeft: "-25px",
        width: "50px",
        height: "50px",
        zIndex: 4
    }

    return (
        <div>
            <div style={loadingGif} className="loader" />
            <div style={loadingStyle}>

            </div>
        </div>
    )
}

export default Loading