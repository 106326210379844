import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import User from '../../models/user'
import SpotGrid from '../../components/spot/spotGrid'
import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Modal,
    ModalBody,
    CardColumns,
    CardDeck
} from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import '../../global.css'
import EditProfile from '../../components/editProfile/editProfile'
import SwitchControl from '../../components/switch-control/switch-control'
import WallGrid from '../../components/wall/wallGrid'
import { useMediaQuery } from 'react-responsive'
import useStories from '../../hooks/useStories'
import { Constants } from '../../constants'
import StoryCard from '../stories/storyCard'
import useCurrentUser from '../../hooks/useUser'
import { v4 as uuidv4 } from 'uuid'
import useUserFromName from '../../hooks/useUserFromName'
import Newsletter from '../../components/newsletter'

const Profile = (props: any) => {

    const VIEW_SPOTS = 'spots'
    const VIEW_WALLS = 'walls'

    const [user, setUser] = useState<User | null>(null)
    const [spotCount, setSpotCount] = useState(0)
    const { username } = useParams()
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)
    const [isCurrentUser, setIsCurrentUser] = useState(false)
    const [viewState, setViewState] = useState(VIEW_SPOTS)

    const [viewWallsFollowing, setViewWallsFollowing] = useState(false)

    const [followingVariants, setFollowingVariants] = useState<("dark" | "light")[]>(['dark'])

    const { firebaseUserDoc } = useUserFromName(username ?? "")

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })

    /** 
    * The variants are used to determine whether a bootstrap button is 'light' or 'dark'
    * Set this value whenever a button is clicked. 
    */
    const [variants, setVariants] = useState<("dark" | "light")[]>(['dark'])

    const [spotsQuery, setSpotsQuery] = useState<firebase.firestore.Query<firebase.firestore.DocumentData>>()
    const [wallsQuery, setWallsQuery] = useState<firebase.firestore.Query<firebase.firestore.DocumentData>>()
    const [followingWallsQuery, setFollowingWallsQuery] = useState<firebase.firestore.Query<firebase.firestore.DocumentData>>()
    const [storiesQuery, setStoriesQuery] = useState<firebase.firestore.Query<firebase.firestore.DocumentData>>()

    const history = useHistory()

    const { currentUser } = useCurrentUser()

    const { stories } = useStories(storiesQuery)

    const [filteredStories, setFilteredStories] = useState(stories)

    useEffect(() => {
        if (!firebaseUserDoc) { return }

        setSpotsQuery(firebase.firestore().collection('tags').where('user_id', '==', firebaseUserDoc.user_id))
        setWallsQuery(firebase.firestore().collection('walls').where('ownerId', '==', firebaseUserDoc.user_id))
        setFollowingWallsQuery(firebase.firestore().collection('walls').where(`followers.${firebaseUserDoc.user_id}`, '==', true))
        setStoriesQuery(firebase.firestore().collection(Constants.Firebase.Stories).where(Constants.Firebase.UserId, '==', firebaseUserDoc?.user_id ?? ""))

    }, [firebaseUserDoc])

    useEffect(() => {
        if (currentUser?.user_id === firebaseUserDoc?.user_id) {
            setIsCurrentUser(true)
        }
    }, [currentUser])

    useEffect(() => {

        if (!firebaseUserDoc) { return }

        // If the user is logged in than get the user document
        if (firebaseUserDoc.user_id) {
            firebase.firestore().collection('users').doc(firebaseUserDoc.user_id).onSnapshot(snapshot => {
                // Once we've downloaded the user object then set the user state object...
                setUser(snapshot.data() as User)
                // ...then let's get the user's spots and walls                
            })
        }
    }, [firebaseUserDoc])

    useEffect(() => {
        if (!stories) { return }

        if (currentUser?.user_id !== firebaseUserDoc?.user_id) {
            setFilteredStories(stories?.filter(s => s.isPublished === true))
        } else {
            setFilteredStories(stories)
        }
    }, [stories, currentUser, firebaseUserDoc])

    /** Get the number of followers for this user */
    const getFollowerCount = () => {
        if (user) {
            return Object.keys(user.followers).length
        }

        return "--"
    }

    const getFollowingCount = () => {
        if (user) {
            return Object.keys(user.following).length
        }

        return "--"
    }

    const profileSpans = {
        fontWeight: "bold" as "bold",
        marginRight: "3px"
    }

    const onHide = () => {
        setShowEditProfileModal(false)
    }

    /** 
     * View spots button is pressed update the state to display the spots of this user and set the color of the spots
     * button to black
     */
    const viewSpots = (variants: ('dark' | 'light')[]) => {
        setVariants(variants)
        setViewState(VIEW_SPOTS)
    }

    /** 
     * View walls button is pressed update the state to display the walls of this user and set the color of the walls
     * button to black
     */
    const viewWalls = (variants: ('dark' | 'light')[]) => {
        setVariants(variants)
        setViewState(VIEW_WALLS)
    }

    return (
        <div>
            {
                spotsQuery && wallsQuery && followingWallsQuery &&
                <div>
                    <Modal size="lg" show={showEditProfileModal} animation={true} onHide={onHide} centered>
                        <ModalBody>
                            <EditProfile user={user} showCancelButton={true} showModal={setShowEditProfileModal} close={() => setShowEditProfileModal(false)} />
                        </ModalBody>
                    </Modal>
                    <Container>
                        <Row className="justify-content-md-center mt-5">
                            <Col md="2">
                                <Image
                                    height="150px"
                                    width="150px"
                                    className={`${!isMobile ? "float-right" : ""} image-fluid`}
                                    src={user?.profile_picture_url}
                                    style={{ objectFit: "cover", borderRadius: "10px", overflow: "hidden" }} />
                            </Col>
                            <Col md="5">
                                <Row>
                                    <Col>
                                        <span style={{ fontWeight: "normal", fontSize: "25px" }}>{user?.username}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <span style={profileSpans}>{getFollowerCount()}</span> Followers
                        </Col>
                                    <Col>
                                        <span style={profileSpans}>{getFollowingCount()}</span> Following
                        </Col>
                                    <Col>
                                        <span style={profileSpans}>{user?.piece_count ?? 0}</span> Spots
                        </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <span style={profileSpans}>{`${user?.first_name ?? ""}  ${user?.last_name ?? ""}`}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span>{user?.bio ?? ""}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <a href={user?.siteUrl} target="_blank"> {user?.siteUrl}</a>
                                    </Col>
                                </Row>
                                {
                                    isCurrentUser &&
                                    <Row className="mt-3">
                                        <Col>
                                            <Button className="rounded-pill" variant="dark" style={{ fontWeight: "bold" }} onClick={() => setShowEditProfileModal(true)}>Edit Profile</Button>
                                        </Col>
                                    </Row>
                                }

                            </Col>
                        </Row>


                        {
                            user && filteredStories &&
                            <Row className="text-center justify-content-md-center">
                                <Col>
                                    <div><hr /></div>
                                </Col>
                                <Col xs="12" className="my-3">
                                    <h1 ><strong>Stories by {user?.username}</strong></h1>
                                </Col>
                                <Col xs="12" style={{ maxWidth: "700px" }}>
                                    <Newsletter />
                                </Col>
                            </Row>
                        }

                        <Row className="justify-content-center">

                            <Col xs="12" md="8" style={{ maxWidth: "700px" }}>
                                <CardDeck>
                                    {
                                        filteredStories && filteredStories.length > 0 &&
                                        filteredStories.map(story => {
                                            return (
                                                <div className="my-4">
                                                    <StoryCard id={story.id} key={story.id} action={story.isPublished ? [{
                                                        text: "Read More",
                                                        callback: () => {
                                                            history.push(`/s/${story.id}`)
                                                        }
                                                    }] : [{
                                                        text: "Finish Story",
                                                        callback: () => {
                                                            history.push(`/c/${story.id}`)
                                                        }
                                                    }]
                                                    } />
                                                </div>
                                            )
                                        })
                                    }
                                </CardDeck>
                            </Col>

                            {
                                filteredStories && filteredStories.length === 0 && isCurrentUser &&
                                <div>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                    <Col className="text-center">
                                        <div className="my-5">
                                            <h2>
                                                You have not created any stories yet.
                                    </h2>
                                            <div className="mt-5">
                                                <Button variant="outline-dark" onClick={() => history.push(`/c/${uuidv4().substring(0, 8)}`)} >+ Share a story</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            }
                            {
                                filteredStories && filteredStories.length === 0 && !isCurrentUser &&
                                <div>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                    <Col className="text-center">
                                        <div className="my-5">
                                            <h2>
                                                This user has not created any stories yet
                                    </h2>
                                        </div>
                                    </Col>
                                </div>
                            }
                        </Row>


                        <hr />
                        <Row className="text-center">
                            <Col className="my-3">
                                <div>
                                    <h2>Here's a list of Spots that I've been to that I think you would love!</h2>
                                </div>
                                <div>
                                    <h4>Save a few of them so that you won't forget to go later.</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="auto">
                                <SwitchControl buttonTitles={['Spots', 'Walls']} buttonFunctions={[viewSpots, viewWalls]} variants={variants} />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            {
                                viewState === VIEW_SPOTS && <Col md="auto">
                                    <SpotGrid query={spotsQuery} setSpotCount={setSpotCount} />
                                </Col>
                            }
                            {
                                viewState === VIEW_WALLS &&
                                <div className="mt-3" style={{ marginLeft: isMobile ? "10px" : "0px" }}>
                                    <SwitchControl
                                        variants={followingVariants}
                                        buttonTitles={['Your Walls', 'Walls User Is Following']}
                                        buttonFunctions={[(variants: ('dark' | 'light')[]) => {
                                            setViewWallsFollowing(false)
                                            setFollowingVariants(variants)
                                        }, (variants: ('dark' | 'light')[]) => {
                                            setViewWallsFollowing(true)
                                            setFollowingVariants(variants)
                                        }]} />

                                    {
                                        viewWallsFollowing === false &&
                                        <Col md="auto">
                                            <WallGrid query={wallsQuery} />
                                        </Col>
                                    }

                                    {
                                        viewWallsFollowing === true &&
                                        <Col md="auto">
                                            <WallGrid query={followingWallsQuery} />
                                        </Col>
                                    }

                                </div>
                            }
                        </Row>
                    </Container>
                </div>
            }
        </div>

    )

}

export default Profile