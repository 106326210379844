import React from 'react'
import { Col, Form } from 'react-bootstrap'

const SimpleTextArea = (props:any) => {

    const simpleInputStyle = (height:number):{} => {
        if (props.header === true) {
            return {
                fontSize: '18px',
                fontWeight: 'bold',
                border: 'none',
                height: height ?? '100px'
            }
        }
        return { 
            fontSize: '16px',
            border: 'none',
            height: height ?? '100px'
        }
    }

    const lineStyle = {
        marginLeft: '15px'
    }    

    const setValue = (e:any) => {
        props.onChange(e.target.value)
    }

    return (        
        <Col style={{ 
            paddingLeft: "0px",
            paddingRight: "0px"
        }}>
            <Form.Control 
                as="textarea" 
                size="lg" 
                type="text" 
                placeholder={props.placeholder} 
                style={simpleInputStyle(props.height)} 
                value={props.value}
                onChange={setValue}
                disabled={props.disabled}/>
            <hr className="mt-0" style={lineStyle}></hr>
        </Col>
    )

}

export default SimpleTextArea