import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import MockObjects from '../objects/mockObjects'
import StoryCard from '../pages/stories/storyCard'
import ShareStoryButton from './shareStoryComponent'

const UseHashtags = () => {

    const history = useHistory()

    return (
        <div>
            <hr></hr>
            <Container className="my-5">
                <Row>
                    <Col xs="12" md="6">
                        <h1 style={{ fontSize: "45px", fontWeight: "bold" }} >
                            Graffiti is designed to help you TELL BETTER TRAVEL STORIES and GET YOUR READERS INVOLVED!
                        </h1>
                        <div className="mt-5" style={{ fontSize: "20px" }}>
                            <p>
                                Now, your readers aren't just going to read about your travels; they can EASILY FOLLOW IN YOUR FOOTSTEPS!
                            </p>
                            <h3>We're Excited For Graffiti To Host The NEXT GENERATION of 6 & 7-figure Travel Bloggers</h3>
                            <p>
                                For the next 12 to 24 months, the travel bloggers who get on this platform FIRST are going to make most of the money.
                            </p>
                        </div>
                        <ShareStoryButton />
                    </Col>
                    <Col xs="12" md="6" className="mt-4 d-flex justify-content-center">
                        <div style={{ maxWidth: "400px" }}>
                            <StoryCard
                                id="My-8-Days-in-Bali-9d99a1f8"
                                mockStory={{ story: MockObjects.eightDaysInBali, imageUrl: '/8daysinbali.jpg' }}
                                action={[{
                                    text: "Read Story",
                                    callback: () => {
                                        history.push(`/s/${"My-8-Days-in-Bali-9d99a1f8"}`)
                                    }
                                }]
                                } />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default UseHashtags