import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Navbar,
  Nav,
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalBody
} from "react-bootstrap"
import firebase from 'firebase'
import './Navbar.css'
import SearchInput from '../../components/search/search'

import SignIn from '../../pages/signIn/signIn'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '../../hooks/useUser'
import AppStoreDownload from '../appStoreDownload'
import CreateTip from '../tips/createTip'

const GraffitiNavbar = (props: any) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [showLoginModal, setShowLoginModal] = useState(false)

  const { currentUser } = useCurrentUser()

  const [showTipScreen, setShowTipScreen] = useState(false)

  const ShowModal = () => {
    return (
      <div>
        {
          showLoginModal &&
          <Modal size="sm" show={true} animation={true} centered>
            <ModalBody>
              <SignIn close={() => setShowLoginModal(false)} />
            </ModalBody>
          </Modal>
        }
      </div>
    )
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })
  }, [])

  const logout = () => {
    if (isLoggedIn) {
      firebase.auth().signOut()
    } else {
      setShowLoginModal(true)
    }
  }

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setIsLoggedIn(false)
    }
  })

  const createSpot = () => {
    if (isLoggedIn) {
      props.history.push('/createspot')
    } else {
      setShowLoginModal(true)
    }
  }

  const createStory = () => {
    if (isLoggedIn) {
      props.history.push(`/c/${uuidv4().substring(0, 8)}`)
    } else {
      setShowLoginModal(true)
    }
  }

  return (
    <div>
      <ShowModal />
      <Modal show={ showTipScreen }>
        <ModalBody>
          <CreateTip close={ () => setShowTipScreen(false) } />
        </ModalBody>
      </Modal>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/home">Graffiti</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Container fluid>
            <Row style={{ width: '100%' }}>
              <Col xs="12" className="padding-left-small-0">
                <Form inline>
                  <SearchInput className="search-input mr-3" />
                  <Link className="links mx-2" to="/tutorials">Tutorials</Link>
                  <div className="my-1">
                    <AppStoreDownload />
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>

          <Nav className="mr-auto">
            <Nav.Link>
              <Link to="/home" className="links">Home</Link>
            </Nav.Link>
            {
              isLoggedIn &&
              <Nav.Link href="#link">
                <Link to={`/p/${currentUser?.username.split(' ').join('_')}`} className="links">Profile</Link>
              </Nav.Link>
            }

            <Button
              className="rounded-pill mr-2 ml-0 mb-2"
              variant="outline-danger"
              onClick={logout}
              style={{ width: "100px", fontWeight: "bold" }}>{isLoggedIn ? "Log Out" : "Log In"}</Button>

            {/* <Button variant="outline-primary" onClick={ () => setShowTipScreen(true)} style={{ width: "150px" }} className="rounded-pill mr-2 mb-2 font-weight-bold">+ Add Tip</Button> */}
            <Button variant="outline-info" onClick={createSpot} style={{ width: "150px" }} className="rounded-pill mr-2 mb-2 font-weight-bold">+ Add a Place</Button>
            <Button variant="outline-success" onClick={createStory} style={{ width: "150px" }} className="rounded-pill mr-2 mb-2 font-weight-bold">+ Share a Story</Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default withRouter(GraffitiNavbar);