import imageCompression from "browser-image-compression";

class Utils {
    // Compress the image
    async compressImage (image: File) {
        const options = {
            maxSizeMB: 0.15,
            maxWidthOrHeight: 2048,
            useWebWorker: false
        }

        try {
            const compressedFile = await imageCompression(image, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            return compressedFile
        } catch (error) {
            return null
        }
    }

}

export default Utils