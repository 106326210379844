import axios from 'axios'
import { SpotLocation } from '../../models/spotLocation'

/**
 * Responsible for retrieving details for a place retrieved using the Google Places API
 */
class PlaceDetails {

    baseUrl = "https://maps.googleapis.com/maps/api/place/details/json"
    readonly LOCALITY = "locality"
    readonly POLITICAL = "political"
    readonly ADMINISTRATIVE_AREA_LEVEL_1 = "administrative_area_level_1"
    readonly ADMINISTRATIVE_AREA_LEVEL_2 = "administrative_area_level_2"
    readonly COUNTRY = "country"
    readonly GOOGLE_API_KEY = "AIzaSyBJs3Y_5p85AMIs9J6F6rbHr1_hH24cVzM"

    /**
     * Gets place detail using the Google Places Details API
     * @param placeId The id of the place to retrieve details for
     * @param sessionToken Each session for autocompletion should have a sessionToken created for it
     */
    async getPlaceDetails (placeId: string):Promise<SpotLocation> {
        
        const { google }:any = window

        const service = new google.maps.places.PlacesService(document.createElement('div'))
        const request = {
            placeId: placeId,
            fields: ['geometry','name','address_component']
        }

        return new Promise((resolve, reject) => {
            service.getDetails(request, (detail:any) => {

                const placeDetail = this.getPlaceObject(detail)

                if (placeDetail) {
                    resolve(placeDetail)
                } else {
                    reject(detail)
                }                
            })
        })             
    }

    /**
     * Gets a placeDetail and converts it into an object that can be saved to a spot's location in Firestore 
     * @param placeDetail A place detail object
     */
    public getPlaceObject (placeDetail:any):SpotLocation {
        const addressComponents:[] = placeDetail.address_components
        let spotLocation:SpotLocation = {} as SpotLocation

        addressComponents.forEach(component => {
            const types:string[] = component["types"]
            
            if (types.indexOf(this.POLITICAL) != -1 && types.indexOf(this.LOCALITY) != -1) {
                spotLocation.city = component["long_name"]
            }
            else if (types.indexOf(this.ADMINISTRATIVE_AREA_LEVEL_2) != -1) {
                spotLocation.city = component["long_name"]
            }
            else if (types.indexOf(this.ADMINISTRATIVE_AREA_LEVEL_1) != -1) {
                spotLocation.state = component["long_name"]
            } else if (types.indexOf(this.COUNTRY) != -1) {
                spotLocation.country = component["long_name"]
            }
        })
                
        spotLocation.location = {
            latitude: (typeof placeDetail.geometry.location.lat == 'number') ? placeDetail.geometry.location.lat : placeDetail.geometry.location.lat(),
            longitude: (typeof placeDetail.geometry.location.lng == 'number') ? placeDetail.geometry.location.lng : placeDetail.geometry.location.lng()
        }

        return spotLocation
    }

}

export { PlaceDetails }