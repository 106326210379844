import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

const Competition = () => {

    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <div className="jumbotron">
                        <h1 className="display-4">1st one to 100 Spots gets $50!</h1>
                        <p className="lead">Deadline for competition - November 15th, 2020</p>
                        <hr className="my-4" />
                        <p>Read below for rules and guidelines for the competition.</p>                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" style= {{ fontSize: "20px" }}>
                    <h4>Here are the rules:</h4>
                    <ul>
                        <li>You may not create any Spot using an image that contains nudity, violence, cruelty, racism, or anything else we deem inappropriate.  <strong>Those who violate this will have their accounts immediately removed.</strong></li>
                        <li>Spots <strong>must be added using our iOS app</strong> since currently that's the only way we can get location data from your phone.</li>
                        <li>Spots must be of good quality.  For example, images that are irrelevant to the Spot will not be included in your list of 100 Spots.</li>
                        <li>Spots that are added in the same place do not count.  For example, adding the same restaurant 100 times will not count as 100 Spots, but only 1 Spot.</li>
                        <li>Your descriptions and Spot name's must not contain anything vulgar, and not contain any curse words.  <strong>Those who violate this will have their accounts immediately removed.</strong></li>
                        <li>We at any time can remove one of your Spots if we feel it is inappropriate or not what Graffiti is intended for.</li>
                        <li>Be respectful of the community.  <strong>Harassment of any form will not be tolerated on Graffiti and will result in a temporary ban or a permanent removal of your account.</strong></li>
                        <li>The 1st one who post 100 Spots approved by Graffiti will be the winner!</li>
                        <li>Payment will be provided via PayPal</li>
                        <li>The deadline for the competition will be November 15th, 2020.</li>
                        <li>The winner will be announced on all of our Social Media Accounts</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs="12" style={{ fontSize: "20px" }}>
                    If you have any other questions, message us at our Instagram Account, <a href="https://www.instagram.com/graffitimapsapp/" target="_blank"><strong>@graffitimapsapp</strong></a>
                </Col>
            </Row>
            <Row className="my-3">
                <Col className="d-flex justify-content-center my-2" >
                    <Image src="/download.png" width="300px" height="100px" onClick={() => window.location.href = 'https://apps.apple.com/gb/app/graffiti-social-location/id1383348851'} ></Image>
                </Col>
            </Row>
        </Container>
    )

}

export default Competition