import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Editor from 'react-medium-editor'
import { Constants } from '../../constants'
import useCurrentUser from '../../hooks/useUser'
import Tip from '../../models/tip'
import Utilities from '../../utilities'
import SimpleTextArea from '../form/textArea'
import Loading from '../loading/loading'

import ShowLogin from '../showLogin'
import "./createTip.css"

const CreateTip = (props: { close: any }) => {

    const [tip, setTip] = useState("")

    const { currentUser, userLoggedIn, saveHashtagsToUser } = useCurrentUser()

    const [saved, setSaved] = useState(false)

    const [error, setError] = useState("")

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setSaved(false)
    }, [tip])

    const publishTip = () => {
        if (!tip) { return }
        
        setSaving(true)

        const strippedSectionContent = Utilities.removeTags(tip)
        const hashtags = Utilities.getHashtagObjectFromString(strippedSectionContent)
        
        const myTip:Tip = {
            content: tip,
            hashtags: hashtags
        }

        firebase.firestore().collection(Constants.Firebase.TipsCollection).add(myTip).then(_ => {
            setSaving(false)
            saveHashtagsToUser(hashtags)
            props.close()
        }).catch(_ => {
            setSaving(false)
            setError("There was a problem publishing your tip. Please check your internet connection and try again.")
            props.close()
        })        
    }

    return (
        <div>
            {
                userLoggedIn && !currentUser &&
                <ShowLogin show={true} />
            }
            {
                saving &&
                <Loading />
            }
            <Container>                
                <Row>
                    <Col>
                        <Button style={{ backgroundColor: "white", border: "none", color: "gray", padding: 0 }} onClick={ props.close }><strong>X</strong></Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="my-3">
                        <SimpleTextArea                             
                            style={{ padding: 0, marginLeft: 0, fontSize: "14px" }}
                            placeholder="What tip you wanna share?"
                            onChange={setTip}/>
                    </Col>
                </Row>
                {
                    error &&
                    <div style={{ color: "red" }}>
                        error
                    </div>
                }
                <Button className="mt-3" style={{ backgroundColor: "white", color: "gray", border: "none", padding: 0 }}  onClick={ publishTip }>
                    <strong>Publish Tip</strong>
                </Button>
            </Container>

        </div>
    )
}

export default CreateTip
