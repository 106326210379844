import React, { useState } from 'react'
import firebase from 'firebase'
import { Row, Col, Button } from 'react-bootstrap'
import SimpleTextArea from './form/textArea'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ProfileSection from './profileSection'
import { Constants } from '../constants';
import StoryObject from '../models/storyObject';
import useCurrentUser from '../hooks/useUser';
import CommentObject from '../models/comment'
import useFirebaseUser from '../hooks/useFirebaseUser';
import ShowLogin from './showLogin'
import ErrorDialog from './errorDialog'


const Discussion = (props: { story: StoryObject }) => {

    const [comment, setComment] = useState<string>("")

    const [comments, setComments] = useState<CommentObject[]>(props.story.comments ?? [])

    const [commenting, setCommenting] = useState(false)

    const { currentUser } = useCurrentUser()

    const [showLogin, setShowLogin] = useState(false)

    const [error, setError] = useState("")

    const submitComment = () => {

        var db = firebase.firestore()

        if (!comment || !currentUser) { return }

        const commentObject: CommentObject = {
            content: comment,
            id: uuidv4().substring(0, 8),
            storyWriter: props.story.user_id,
            commenter: currentUser.user_id
        }

        db.collection(Constants.Firebase.Stories).doc(props.story.id).update({
            comments: firebase.firestore.FieldValue.arrayUnion(commentObject)
        })

        addCommentState(commentObject)
        setComment("")
        setCommenting(false)

    }

    /** Update the comments state array */
    const addCommentState = (comment: CommentObject) => {
        let myComments = comments
        myComments.push(comment)
        setComments(myComments)
    }

    const removeCommentState = (comment: CommentObject) => {
        setComments(comments.filter(c => c.id !== comment.id))
    }

    const ProfileSectionWithUserId = (props: { userId: string }) => {

        const { firebaseUserDoc } = useFirebaseUser(props.userId)

        return (
            <div>
                {
                    firebaseUserDoc &&
                    <ProfileSection user={firebaseUserDoc} />
                }
            </div>
        )
    }

    const removeComment = (comment: CommentObject) => {

        var db = firebase.firestore()

        if (!comment || !currentUser) { return }

        db.collection(Constants.Firebase.Stories).doc(props.story.id).update({
            comments: firebase.firestore.FieldValue.arrayRemove(comment)
        })

        removeCommentState(comment)
        setComment("")
        setCommenting(false)
    }

    return (
        <div className="my-2">
            {
                error &&
                <ErrorDialog error={error} setError={setError} />
            }

            <ShowLogin show={showLogin} close={() => setShowLogin(false)} />
            {
                !commenting &&
                <Row className="mb-5 text-center">
                    <Col xs="12">
                        <Button className="rounded-pill" onClick={() => {
                            if (!currentUser) {
                                setShowLogin(true)
                                return
                            }

                            setCommenting(true)
                        }} variant="outline-dark">+ Comment on Story</Button>
                    </Col>
                </Row>
            }

            {
                commenting &&
                <Row style={{ backgroundColor: "white" }}>
                    <Col xs="12" className="mt-2">
                        <SimpleTextArea
                            value={comment ?? ""}
                            headerName="What do you think?"
                            placeholder="Write your comment"
                            header={false}
                            onChange={setComment} />
                    </Col>
                    <Col xs="12" className="mt-3 mb-3">
                        <Button className="rounded-pill" onClick={submitComment} variant="outline-dark">Comment</Button>
                        <Button className="rounded-pill ml-3" onClick={() => setCommenting(false)} variant="outline-danger">Cancel</Button>
                    </Col>
                </Row>
            }
            {
                comments.length > 0 &&
                comments.map(comment =>
                    <div>
                        <Row key={comment.id} className="mt-3">
                            <Col xs="1" className="d-flex align-items-center text-center">
                                <span className="align-middle">
                                    {/* The delete comment button */}
                                    {
                                        currentUser && currentUser.user_id === comment.commenter &&
                                        < Button style={{ backgroundColor: "white", border: "none" }} onClick={() => removeComment(comment)}>
                                            <FontAwesomeIcon icon={faTimes} style={{ color: "lightGray" }} />
                                        </Button>
                                    }
                                </span>
                            </Col>
                            <Col xs="11">
                                <ProfileSectionWithUserId userId={comment.commenter} />
                                <div className="mt-3" style={{ borderRadius: "25px" }}>{comment.content}</div>
                            </Col>
                        </Row>
                    </div>
                )
            }
            {
                comments.length === 0 &&
                <div className="m-5 text-center">
                    <strong>Be the first to comment!</strong>
                </div>
            }
        </div >
    )
}

export default Discussion