import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Image, Modal, ModalBody } from 'react-bootstrap'
import SimpleInput from '../form/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase'
import WallObject from './wallObject'
import { withRouter } from 'react-router-dom'
import SpotObject from '../spot/spotObject'
import Loading from '../loading/loading'
import { v4 as uuidv4 } from 'uuid'

const AddToWall = (props: any) => {

    const [walls, setWalls] = useState<WallObject[]>([])
    /** The spot the user is adding to a wall */
    const spot: SpotObject = props.spot

    const [processing, setProcessing] = useState(false)

    const [error, setError] = useState<String | null>(null)

    const [wallName, setWallName] = useState<string | null>(null)

    useEffect(() => {
        getWallsForCurrentUser()
    }, [])

    const getWallsForCurrentUser = () => {
        const userId = firebase.auth().currentUser?.uid

        if (!userId) {
            return
        }

        setProcessing(true)

        firebase.firestore().collection('walls').where('ownerId', '==', userId).get().then(snapshot => {
            const walls = snapshot.docs.map(doc => {
                return {
                    data: doc.data(),
                    id: doc.id
                } as WallObject
            })

            setWalls(walls)
            setProcessing(false)
        }).catch(error => {
            setProcessing(false)
        })

    }

    const onHide = () => {

    }

    /**
     * Creates a wall with the given wall name and the first Spot added as the Wall's main image
     * @param wallName The name of the wall
     */
    const createWall = async (wallName: string | null) => {
        const userId = firebase.auth().currentUser?.uid

        if (!userId) {
            return
        }

        if (!wallName) {
            setError("Please set a Wall name or add this Spot to an already existing Wall")
            return
        }

        setProcessing(true)

        let wall: WallObject = {
            data: {
                name: wallName,
                ownerId: userId,
                spots: {
                    [spot.id]: true
                },
                thumbnailImageUrl: spot.data.thumbnail_url ?? spot.data.picture_url,
                fullSizeImageUrl: spot.data.picture_url
            },
            id: uuidv4()
        }

        try {
            await firebase.firestore().collection('walls').doc(wall.id).set(wall.data)
            await addSpotToWall(wall)
        } catch (err) {
            setError(err)
        }

        setProcessing(false)
    }

    /** Save a Spot to a wall */
    const addSpotToWall = async (wall: WallObject) => {
        setProcessing(true)

        try {
            // Update the wall to show the spot as one of it's spots
            await firebase.firestore().collection('walls').doc(wall.id).update({
                [`spots.${spot.id}`]: true
            })
        } catch (err) {
            setError(err)
            setProcessing(false)
            return
        }

        try {
            // Update the spot to show that its now on this specific wall
            await firebase.firestore().collection('tags').doc(spot.id).update({
                [`wall.${wall.id}`]: true
            })
        } catch (err) {
            setError(err)
            setProcessing(false)
            return
        }

        // Show the user the wall that they've just added the Spot to
        props.history.push(`/wall/${wall.id}/${wall.data.name}`)

        setProcessing(false)
    }

    return (
        <div>
            {
                processing && <Loading />
            }
            <Modal show={true} animation={true} onHide={onHide} centered>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col xs="12" className="mt-2">
                                {
                                    error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                }
                            </Col>
                            <Col xs="2" className="pl-1">
                                <Button className="font-weight-bold" variant="dark" onClick={props.exitButtonPressed}>X</Button>
                            </Col>
                            <Col xs="8" className="pr-0">
                                <SimpleInput placeholder="New wall name" onChange={setWallName} />
                            </Col>
                            <Col xs="2">
                                <Button className="rounded-pill" variant="dark" onClick={() => createWall(wallName)}> <FontAwesomeIcon icon={faPlus} /> </Button>
                            </Col>
                        </Row>

                        {
                            walls.map(wall => {
                                return (
                                    <div>
                                        {
                                            processing && <Loading />
                                        }
                                        <Row className="mt-2">
                                            <Col xs="3" md="2" className="pl-1">
                                                <Image
                                                    style={{ borderRadius: '5px' }}
                                                    className="graffiti-image"
                                                    height="50"
                                                    width="50"
                                                    src={wall.data.thumbnailImageUrl} />
                                            </Col>
                                            <Col xs="7" md="8" className="d-flex align-items-center">
                                                <div className="font-weight-bold">{wall.data.name}</div>
                                            </Col>
                                            <Col xs="2" className="d-flex align-items-center">
                                                <Button variant="dark" className="rounded-pill" onClick={() => addSpotToWall(wall)}><FontAwesomeIcon icon={faPlus} /></Button>
                                            </Col>
                                        </Row>
                                    </div>

                                )
                            })
                        }
                    </Container>
                </ModalBody>
            </Modal>
        </div>
    )

}

export default withRouter(AddToWall)