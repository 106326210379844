import React, { useState } from 'react'
import { useEffect } from 'react'
import { Container, Row, Col, Image, Modal, ModalBody } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import VideoModal from '../../components/videoModal'
import { Constants } from '../../constants'

const Tutorial = () => {

    const [showVideo, setShowVideo] = useState(false)
    const [video, setVideo] = useState<any>()

    const { id } = useParams()

    const videos = [{
        url: "https://vimeo.com/467438073",
        id: "createSpot",
        imageSrc: "/tutorialImages/addSpot.png",
        subCaption: "How to create a spot from an already existing picture",
        caption: "How To Create A Spot",
        description: "Creating a spot is at the heart of Graffiti.  When you save a Spot, it's location information is automatically saved so that others can view your Spots on a map and visit them, share them with friends, etc.  With Spots, you can remember exactly where all the cool places you've been are so that others can follow in your footsteps.  Little hole in the walls, cool places to eat on the side of the street, awesome view of a secluded waterfall.  You can share all these places and people can view them and travel there like they're using Google Maps!"
    },
    {
        url: "https://vimeo.com/460779448",
        imageSrc: "/tutorialImages/createWall.png",
        id: "createWall",
        subCaption: "Walls are great for organizing and planning trips",
        caption: "How To Create A Wall",
        description: "'Walls' are great for planning trips and keeping the 'Spots' that you've created organized.  You can also follow other people's 'Walls'.  'Walls' can be something like, 'Favorite Cafe's in San Francisco', 'Best Places to Hike In San Diego', 'Best Mom and Pop Shops in Las Vegas'.  The great thing is that you can easily navigate to all the 'Spots' on a 'Wall' with just a few taps.  Jump over to the iOS app and try this awesome feature!"
    }]

    useEffect(() => {
        const videoToShow = videos.filter( v => v.id === id )

        if (videoToShow.length > 0) {
            setVideo(videoToShow[0])
            setShowVideo(true)
        }
        
    }, [ id ] )

    const videoClicked = (video: any) => {
        setShowVideo(true)
        setVideo(video)
    }

    const videoClosed = () => {
        setVideo(undefined)
        setShowVideo(false)
    }

    return (
        <div>
            {
                showVideo && video &&
                <Modal show={true} animation={true} size="lg" centered>
                    <ModalBody>
                        <VideoModal video={video} close={() => videoClosed()} />
                    </ModalBody>
                </Modal>
            }
            <Container style={{ maxWidth: "800px", backgroundColor: Constants.Colors.BackgroundColor }} className="mb-5" >

                <Row className="d-flex justify-content-center">

                    <Col xs="12" className="mt-5">
                        <div className="jumbotron mt-2" style={{ backgroundColor: Constants.Colors.Tan }}>
                            <h1 className="display-4" >Video Tutorials</h1>
                            <p className="lead"></p>
                            <hr className="my-4" style={{ backgroundColor: "white" }} />
                            <p>
                                On this page you'll be able to view videos that teach you how to use the most important features in Graffiti Social Location.  If you have any other questions, feel free to email me at <strong>adebayoiji@gmail.com</strong>
                            </p>
                            <p>
                                Click on one of the boxes below to take you to play the video
                            </p>
                        </div>

                    </Col>
                </Row>

                {
                    <Row>
                        {
                            videos.map(video => {
                                return (
                                    <Col xs="12" md="4" className="mt-3">
                                        <Row onClick={() => videoClicked(video)}>
                                            <Col xs="12">
                                                <Image src={video.imageSrc} thumbnail />
                                            </Col>
                                            <Col xs="12" className="text-center mt-2">
                                                {video.subCaption}
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })

                        }
                    </Row>
                }

            </Container>
        </div>
    )

}

export default Tutorial