import React from 'react'
import { Container } from 'react-bootstrap'
import SpotGrid from '../../components/spot/spotGrid'
import ChangeViewSwitch from '../../components/navbar/changeViewSwitch'
import Fire from '../../fire'
import TestimonialsList from '../../components/testimonials/testimonialsList'
import { Constants } from '../../constants'

const TestimonialPage = () => {

    const getAllSpotsQuery = Fire.firestore().collection('tags').limit(24).orderBy('time_added', 'desc')

    return (
        <Container>

            <TestimonialsList testimonials={Constants.testimonials} />

            <div className="my-5">
                <div style={{ fontSize: "55px" }} className="text-center">
                    <strong>Most recent spots on Graffiti Maps</strong>
                </div>
                <ChangeViewSwitch />
                <SpotGrid query={getAllSpotsQuery} />
            </div>
        </Container>
    )
}

export default TestimonialPage