import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import AddImage from '../../components/form/addImage'
import SimpleInput from '../../components/form/input';
import SimpleTextArea from '../../components/form/textArea';
import PlacesAutocomplete from '../autocomplete/googleAddressAutocomplete';
import { AWSService } from '../../services/aws'
import { v4 as uuidv4 } from 'uuid'
import { firestore } from 'firebase'
import { SpotLocation } from '../../models/spotLocation';
import './createSpot.css'
import SpotFirebaseObject from '../spot/spotFirebaseObject';
import { withRouter } from 'react-router-dom';
import Utils from '../../utils/images'
import firebase from 'firebase'
import Loading from '../loading/loading';

const CreateSpot = (props: any) => {

    /** The div that will show the image once its added */
    const imageRef = useRef<JSX.Element>(null)
    /** URL of the image selected by the user  */
    const [imageFileUrl, setImageFileUrl] = useState("")
    /** The address of the spot */
    const [address, setAddress] = useState("")
    /** The address pulled from an image */
    const [validatedAddress, setValidatedAddress] = useState("")
    /** The name of the Spot */
    const [spotName, setSpotName] = useState("")
    /** The description for the spot */
    const [spotDescription, setSpotDescription] = useState("")
    /** The location details for the Spot */
    const [spotLocationDetails, setSpotLocationDetails] = useState<SpotLocation | undefined>()
    /** The image for this Spot */
    const [spotImage, setSpotImage] = useState<File | Blob>({} as File | Blob)
    /** Whether the Spot is currently saving */
    const [saving, setSaving] = useState(false)
    /** Any error that needs to be displayed to the user */
    const [error, setError] = useState<string | null>(null)

    const [hiResImage, setHiResImage] = useState<File | Blob>({} as File | Blob)

    // When the image is has been selected, than we compress it and save it as a state object
    const onImagesAdded = async (images: File[]) => {
        const utils = new Utils()

        if (images.length == 0) {
            return
        }

        // Compress the image
        const compressedImage = await utils.compressImage(images[0])

        if (!compressedImage) { return }

        setImageFileUrl(URL.createObjectURL(compressedImage))
        setSpotImage(compressedImage)
        setHiResImage(images[0])

    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                props.history.push('/home')
            }
        })        
    }, [])

    const resetLocation = () => {
        setAddress("")
        setValidatedAddress("")
        setSpotLocationDetails(undefined)
    }

    const spotLocationRetrieved = (spotLocation: SpotLocation) => {
        setSpotLocationDetails(spotLocation)
        setValidatedAddress(spotLocation.address)
        setAddress(spotLocation.address)
        console.log("The validated address is " + spotLocation.address)
    }

    const handleAddressChanged = (value: any) => {
        setAddress(value)
    };

    /** User presses the save button and we save the image to S3 */
    const saveButtonPressed = async () => {

        if (!spotLocationDetails) {
            setError("Please add a location")
            return
        }

        if (!spotImage.type) {
            setError("Please add an image")
            return
        }
        else if (!spotName) {
            setError("Please enter a name for the Spot")
            return
        } else if (!address) {
            setError("Please enter an address for this Spot")
            return
        }

        const spotId = uuidv4()
        const userId = firebase.auth().currentUser?.uid

        if (!userId) {
            return
        }

        // Set the app's state to saving so that the publish spot button is updated to display that the spot is currently saving
        setSaving(true)
        const awsService = new AWSService(
            userId, /** User Id */
            imageFileUrl /** The local url of the file to upload */)

        const hiResImageAddress = await awsService.uploadImage(spotId, hiResImage, `images/spot-images/hi-res/${userId}/${spotId}/${uuidv4()}`)

        // Save the image first
        awsService.uploadImage(spotId, spotImage, `images/spot-images/${userId}/${spotId}/${uuidv4()}`).then(imageAddress => {
            console.log('Image saved')

            let hashtags = spotDescription.split(/(?=#)/g)
            hashtags = hashtags.filter(object => {
                return object.startsWith('#')
            })

            let hashtagObject = {} as any

            hashtags.map(hashtag => {
                const noHashtag = hashtag.split(' ')[0].replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '')
                firebase.firestore().collection('hashtags').add({
                    name: noHashtag
                })

                hashtagObject[noHashtag] = true
            })

            let spot: SpotFirebaseObject = {
                ...spotLocationDetails,
                id: spotId,
                description: spotDescription,
                name: spotName,
                hashtags: hashtagObject,
                picture_url: imageAddress as string,
                time_added: Math.floor(Date.now() / 1000),
                user_id: userId,
                hi_res_url: hiResImageAddress as string
            }

            // Save the rest of the data
            firestore().collection('tags').doc(spotId).set(spot).then(() => {
                setSaving(false)
                setError(null)
                if (props.spotCreated) {                    
                    props.spotCreated(spotId)
                    if (props.close) {
                        props.close()
                    }                    
                } else {
                    props.history.push(`/viewspot/${spotId}/${spot.name}`)
                }
                
            }).catch(error => {
                console.log(`Error: ${error}`)
                setError("There was an error saving the Spot.  Please try again.")
                setSaving(false)
            })
        }).catch(error => {
            setError("There was an uploading the image.  Please try again.")
            setSaving(false)
            console.log(error)
        })
    }

    return (
        <div>
            {
                saving &&
                <Loading />
            }
            <Container className="mt-3" style={{ maxWidth: "800px" }}>
                <Row>
                    <Col>

                        <div className="mb-2">
                            <strong>Please read:</strong>
                        </div>

                        <div className="alert alert-info">
                            <i>* It's best if you <strong>use images that you took on your phone</strong> since <strong>those images typically have location data stored inside of them</strong>.  If the photo that you're adding has location data within it, then the address where you took the photo will show here.  Otherwise, you'll have to manually add the address at the bottom yourself.</i>
                            {
                                validatedAddress &&
                                <div className="mt-3">

                                    <div>
                                        Awesome! This photo has location data in it! This photo was taken at:
                                        </div>
                                    <strong>
                                        <div>
                                            {validatedAddress}
                                        </div>
                                    </strong>
                                </div>
                            }

                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <AddImage resetLocation={resetLocation} onImagesAdded={onImagesAdded} spotLocationRetrieved={spotLocationRetrieved} ref={imageRef} />
                </Row>
                <Row>
                    <Col xs="12" className="mt-3" style={{ height: '100px' }}>
                        <SimpleInput
                            placeholder="What do you call this place?"
                            header={true}
                            onChange={setSpotName} />
                    </Col>
                    <Col xs="12" style={{ height: '100px' }}>
                        <SimpleTextArea
                            placeholder="Write some information about this Spot.  Go ahead and throw some #hashtags in there too. "
                            value={spotDescription}
                            onChange={setSpotDescription} />
                    </Col>
                    {
                        !validatedAddress &&
                        <Col xs="12" className="mt-3">
                            <PlacesAutocomplete
                                handleInputChanged={handleAddressChanged}
                                spotLocationRetrieved={spotLocationRetrieved}
                                value={address} />
                        </Col>
                    }
                    {
                        validatedAddress &&
                        <Col xs="12" className="mt-3">
                            <div className="alert alert-success">Address retrieved for photo:</div>
                            <strong>{validatedAddress}</strong>
                        </Col>
                    }
                    <Col xs="12" className="mt-2">
                        {
                            error &&
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        }
                    </Col>
                    <Col className="mt-2 mb-5 ml-4">
                        <Button variant="dark" className="rounded-pill" onClick={saveButtonPressed}>Publish Spot</Button>
                        {
                            props.close &&
                            <Button variant="danger" className="ml-2 rounded-pill" onClick={props.close}>Cancel</Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >

    )
}

export default withRouter(CreateSpot);