import React from 'react'
import { Col, Container, Row, Image, Button } from 'react-bootstrap'
import ShareStoryButton from '../components/shareStoryComponent'
import { useHistory } from 'react-router-dom'
import SpotCard from './spotCard'
import MockObjects from '../objects/mockObjects'

const ShareWalls = () => {

    const history = useHistory()

    return (
        <div>
            <hr></hr>
            <Container className="my-5">
                <Row>
                    <Col xs="12" md="6" className="mt-4 d-flex justify-content-center">
                        <div style={{ maxWidth: "400px" }}>
                            <SpotCard 
                            mockSpot={ { spot: MockObjects.howtheCliffPathSpot, imageUrl: "/howthecliffpath.jpg" } } 
                            id="62967951-AFED-45A1-A2DD-25093D908D88" />
                        </div>
                    </Col>

                    <Col xs="12" md="6" className="mt-5">
                        <h1 style={{ fontSize: "45px", fontWeight: "bold" }} >
                            Here are some of the ways Graffiti is going to help you MAKE MORE MONEY as a travel blogger
                        </h1>
                        <h3 className="mt-5">#1: The stories you tell with Graffiti are optimized so that they can show up on Google</h3>
                        <div className="mt-4" style={{ fontSize: "20px" }}>
                            <p>You don't have to do boring SEO work. We do that for you. 😊👍</p>

                            <p>As you tell your AMAZING travel stories on Graffiti, we work to get those stories to show up high in Google.</p>

                            <p>Of course, you need to write something amazing, but we do the rest.</p>

                            <p>As future travelers Google their favorite travel destinations, they will read your stories on Graffiti. And they will follow your spots.</p>

                            <p>All successful travel bloggers know, it's easier to make money as MORE PEOPLE DISCOVER YOU.</p>

                            <p>And, to make you stand out even more...</p>
                            <h3>#2: If you are one of the first 1,000 travel bloggers to share your story on Graffiti, you will be forever known as a FOUNDER!
                        </h3>
                            <p className="mt-4">
                                As a founder, you will get a badge next to your profile that tells other users that you have been blogging on this platform since the beginning.  It's like being a verified user on Instagram!</p>

                            <p>That's one more way that writing a travel story on Graffiti NOW can help you GROW YOUR REPUTATION and income.</p>

                            <p>If you wait until later to post your first story to Graffiti it will be TOO LATE. You will have MISSED your chance to get in on the ground floor of this exciting new travel blogging platform.</p>

                            <p>Imagine how much money the FIRST influencers on Instagram made. It's so much harder to be an influencer on Instagram today because the market is so crowded. That's why being on Graffiti from THE BEGINNING makes it easier for you to STAND-OUT and MAKE MONEY.</p>
                        </div>
                        <ShareStoryButton />
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default ShareWalls