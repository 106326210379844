import React, { useEffect, useState } from 'react'
import Fire from '../../fire'
import { Container, Row, Col } from 'react-bootstrap'
import Wall from './wall'
import WallObject from './wallObject'

const WallGrid = (props:any) => {

    const [walls, setWalls] = useState([] as WallObject[])

    // Called immediately
    useEffect(() => {

        const query = props.query

        if (query) {
            query.get().then( (snapshot:any) => {
                setWalls(getWallsFromSnapshot(snapshot))
            })
            
            return
        }

        Fire.firestore().collection('walls').limit(100).get()
            .then(snapshot => {
                setWalls(getWallsFromSnapshot(snapshot))
            })
    }, []) // By passing an empty array as the second argument of useEffect we ensure that useEffect is only called once

    const getWallsFromSnapshot = (snapshot:firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>):WallObject[] => {
        const walls = snapshot.docs.map(doc => {
            return {
                data: doc.data(),
                id: doc.id
            } as WallObject
        })

        return walls
    }

    return (
        <Container className="mt-5">
            <Row>
                {
                    walls.map(wall => {
                        return (
                            <Col xs="12" lg="3" md="4" xl="3" sm="6">
                                <Wall
                                    key={wall.id}
                                    title={wall.data.name}
                                    imageUrl={wall.data.fullSizeImageUrl}
                                    id={wall.id} />
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default WallGrid