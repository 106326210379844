import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { Constants } from '../constants'

const Newsletter = () => {

    const [error, setError] = useState("")

    const [email, setEmail] = useState("")

    const [complete, setComplete] = useState(false)

    useEffect(() => {
        setError("")
    }, [email])

    const saveEmail = () => {
        firebase.firestore().collection(Constants.Firebase.EmailsCollection).add( { email: email }).then()
    }

    const submit = () => {

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(email)) {
            setComplete(true)
            saveEmail()
        }
        else {
            setError("Please enter a valid email")
        }
    }

    return (
        <div className="my-5 p-5 text-center" style={{ borderWidth: "1px", borderColor: "black", borderStyle: "dashed" }}>
            {
                !complete &&
                <div>

                    <h2>
                        <strong>Join The Club</strong>
                    </h2>
                    <div style={{ fontSize: "16px" }}>
                        <strong>Receive weekly emails with travel updates, tips, and advice from all over the world.</strong>
                    </div>
                    <div className="mt-3">
                        <strong>Learn how you can make money from travel.</strong>
                    </div>
                    <div className="mt-3">
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Your email address"
                                aria-label="Your email address"
                                aria-describedby="basic-addon2"
                                onChange={(e: any) => { setEmail(e.target.value) }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text style={{
                                    backgroundColor: "black",
                                    color: "white"
                                }} onClick={() => submit()} id="basic-addon2">Sign Me Up</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <div style={{ color: "red" }}>
                        {error}
                    </div>
                </div>
            }
            {
                complete &&     
                <div>           
                    <h1><strong>Thanks for joining!</strong></h1>
                    <div>
                        Awesome info is coming your way!
                    </div>
                </div>
            }

        </div>
    )
}

export default Newsletter