import { title } from 'process'
import React from 'react'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, PinterestIcon, PinterestShareButton, LineIcon, LineShareButton, RedditShareButton, RedditIcon } from 'react-share'

const Share = (props: { name: string, url: string, imageUrl?: string, message?: string }) => {

    const message = `Check out this cool spot ${props.name} on Graffiti Maps`

    return (        
        <div className="mb-3">            
            < FacebookShareButton
                url={props.url}
                quote={props.message ?? message} 
                className="mt-2">                    
                <FacebookIcon size={32} round />
            </FacebookShareButton >
            <TwitterShareButton
                url={props.url}
                title={props.message ?? message}
                className="ml-2" >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
                url={props.url}
                title={props.message ?? message}
                separator=":: "
                className="ml-2">
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <PinterestShareButton
                url={String(window.location)}
                media={props.imageUrl ?? ""}
                className="ml-2">
                <PinterestIcon size={32} round />
            </PinterestShareButton>
            <LineShareButton
                url={props.url}
                title={props.message ?? message}
                className="ml-2"
            >
                <LineIcon size={32} round />
            </LineShareButton>

            <RedditShareButton
                url={props.url}
                title={title}
                windowWidth={660}
                windowHeight={460}
                className="ml-2"
            >
                <RedditIcon size={32} round />
            </RedditShareButton>
        </div>
    )

}

export default Share