import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ShareStoryButton from '../../components/shareStoryComponent'
import MockObjects from '../../objects/mockObjects'
import StoryCard from '../stories/storyCard'

const SignUp = () => {

    const history = useHistory()

    return (

        <Container>
            <Row className="my-5">
                <Col xs="12" md="6" className="d-flex justify-content-center">
                    <div style={{ maxWidth: "400px" }}>
                        <StoryCard id="WhyuseGraffitiMapsforyourtravelblog-8d7365e0"
                            mockStory={{ story: MockObjects.whyUseGraffiti, imageUrl: "/whyusegraffiti.jpg" }}
                            action={[{
                                text: "Read Story",
                                callback: () => {
                                    history.push(`/s/${"WhyuseGraffitiMapsforyourtravelblog-8d7365e0"}`)
                                }
                            }]
                            } />
                    </div>
                </Col>

                <Col xs="12" md="6" className="mt-5">
                    <h1 style={{ fontSize: "45px", fontWeight: "bold" }}>
                        If you want to be the next 6 & 7-figure travel blogger, NOW is the time to start telling your stories through GRAFFITI!
                    </h1>
                    <div className="mt-5" style={{ fontSize: "20px" }}>
                        <p>
                            With Graffiti, as you write your travel blog articles, you can easily take any picture from your mobile phone and ADD IT TO YOUR STORY.
                            </p>
                        <p>
                            But... THAT'S NOT ALL...! When you add your picture, Graffiti will AUTOMATICALLY figure out WHERE you took the picture, and you can post it in your story with that location!
                        </p>

                        <h2 className="my-2">Through the Graffiti App, your readers can:</h2>

                        <ol>
                            <li>
                                <strong>FOLLOW YOU</strong> so that they get alerts when you share new Travel stories
                            </li>
                            <li>
                                <strong>GET ALERTS</strong> every time you share one of your AMAZING new spots in the Graffiti App!
                            </li>
                            <li>
                                <strong>PLAN TRIPS</strong> by saving your travel spots to their own account
                            </li>
                            <li>
                                <strong>SHARE</strong> your amazing travel spots with their friends
                            </li>
                        </ol>
                    </div>
                    <ShareStoryButton />
                </Col>
            </Row>

        </Container >

    )

}

export default SignUp
