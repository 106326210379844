import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import GraffitiNavbar from './components/navbar/Navbar'
import Home from "./pages/home/home";
import ViewSpot from "./components/spot/viewSpot";
import Walls from "./components/wall/walls";
import SpotsOnWall from "./components/wall/spotsOnWall";
import CreateSpot from "./components/create-spot/createSpot";
import SignIn from './pages/signIn/signIn'
import firebase from "firebase";
import Profile from "./pages/profile/profile";
import SearchPage from "./pages/search";
import Tutorial from "./pages/tutorial/tutorial";
import { Constants } from "./constants";
import Competition from "./pages/competition/competition";
import { hotjar } from "react-hotjar";
import LiveChat from 'react-livechat'
import { Button, Col, Container, Image, Modal, ModalBody, Row } from 'react-bootstrap'
import TestimonialsList from "./components/testimonials/testimonialsList";
import TestimonialPage from "./pages/testimonial/testimonialPage";
import AppStoreDownload from "./components/appStoreDownload";
import Instagram from "./pages/instagramAffiliate.tsx/instagram";
import CreateStory from "./pages/stories/createStory";
import ViewStory from "./pages/stories/viewStory";
import Main from "./pages/main/main";
import MainPage from "./pages/main/main";
import useCurrentUser from "./hooks/useUser";
import EditProfile from "./components/editProfile/editProfile";
import ReactPixel from 'react-facebook-pixel'

export default function App() {
  

  const [showLoginModal, setShowLoginModal] = useState<boolean | null>(null)

  const { currentUser } = useCurrentUser()

  const onHide = () => {

  }

  useEffect(() => {

    hotjar.initialize(2056087, 0)

    ReactPixel.init('166877477315702');
    ReactPixel.pageView()

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setShowLoginModal(false)
      } else {
        setShowLoginModal(true)
      }
    })
  }, [])

  const userSignedIn = () => {
    setShowLoginModal(false)
  }

  return (

    <div style={{ backgroundColor: Constants.Colors.BackgroundColor }}>

      {
        currentUser &&
        <Modal size="lg" show={currentUser.username == ""} animation={true} onHide={onHide} centered>
          <ModalBody>
            <EditProfile user={currentUser} close={() => currentUser.username != ""} />
          </ModalBody>
        </Modal>
      }

      <LiveChat license="12295902" />

      <Router>

        <GraffitiNavbar />

        <div>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>

            <Route path='/main'>
              <MainPage />
            </Route>

            <Route path='/c/:draftId'>
              <CreateStory />
            </Route>
            <Route path="/instagram">
              <Instagram />
            </Route>
            <Route path="/s/:storyId">
              <ViewStory />
            </Route>
            <Route path="/testimonials">
              <TestimonialPage />
            </Route>
            <Route path="/competition">
              <Competition />
            </Route>
            <Route path="/tutorials/:id">
              <Tutorial />
            </Route>
            <Route path="/tutorials">
              <Tutorial />
            </Route>
            <Route path="/search/:hashtag">
              <SearchPage />
            </Route>
            {/* <Route path="/profile/:username">
              <Profile />
            </Route> */}
            <Route path="/wall/:wallId/:wallName/:expanded">
              <SpotsOnWall />
            </Route>
            <Route path="/wall/:wallId/:wallName">
              <SpotsOnWall />
            </Route>
            <Route path="/walls">
              <Walls />
            </Route>
            <Route path="/viewspot/:spotId/:spotName">
              <ViewSpot />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/createspot">
              <CreateSpot />
            </Route>
            <Route path="/signin/:redirectUrl">
              <SignIn />
            </Route>
            <Route path="/p/:username">
              <Profile />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>


  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}