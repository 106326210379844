import React from 'react'
import { CardColumns, CardDeck } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import StoryObject from '../models/storyObject'
import StoryCard from '../pages/stories/storyCard'

const StoriesGrid = (props: { stories?: StoryObject[], action: any }) => {

    const history = useHistory()

    return (
        <CardDeck>

            {
                props.stories && props.stories.length > 0 &&
                props.stories.map(story => {
                    return (
                        <StoryCard id={story.id} key={story.id} action={story.isPublished ? [{
                            text: "Read More",
                            callback: () => {
                                history.push(`/s/${story.id}`)
                                if (props.action) {
                                    props.action()
                                }
                            }
                        }] : [{
                            text: "Finish Story",
                            callback: () => {
                                history.push(`/c/${story.id}`)
                            }
                        }]
                        } />
                    )
                })
            }
            {
                props.stories && props.stories.length == 0 &&
                <div className="text-center">
                    <div className="my-5">
                        <h2>
                            No stories
                         </h2>
                    </div>
                </div>
            }
        </CardDeck>

    )

}

export default StoriesGrid