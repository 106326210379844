import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Container, Row, Col, Image, Badge, Modal, ModalBody, Button, ModalTitle } from 'react-bootstrap';
import SwitchControl from '../switch-control/switch-control';
import { useParams, withRouter } from 'react-router-dom';
import Fire from '../../fire'
import Utilities from '../../utilities';
import './spot.css'
import './viewspot.css'
import '../../global.css'
import ChangeViewSwitch from '../navbar/changeViewSwitch';
import Loading from '../loading/loading';
import AddToWall from '../wall/addToWall'
import { firestore } from 'firebase'
import WallGrid from '../wall/wallGrid';
import SignIn from '../../pages/signIn/signIn';
import firebase from 'firebase'
import EditSpot from './editSpot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Share from '../share';
import ProfileSection from '../profileSection';
import Newsletter from '../newsletter';
import { Constants } from '../../constants';

const ViewSpot = (props: any) => {

    let { spotId } = useParams()
    const [spot, setSpot] = useState({ data: { hashtags: {} } } as any)
    const [user, setUser] = useState({} as any)
    const [showSaveSpotModal, setShowSaveSpotModal] = useState(false)
    const [getRelatedWallsQuery, setGetRelatedWallsQuery] = useState<firestore.Query | null>(null)
    const [showEditSpot, setShowEditSpot] = useState(false)

    const [showLoginModal, setShowLoginModal] = useState(false)

    const [isCurrentUsersSpot, setIsCurrentUsersSpot] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const ShowModal = () => {
        return (
            <div>
                {
                    showLoginModal &&
                    <Modal size="sm" show={true} animation={true} centered>
                        <ModalBody>
                            <SignIn close={() => setShowLoginModal(false)} />
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }

    const save = () => {
        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            setShowSaveSpotModal(true)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        if (spot.id) {
            window.location.reload()
        }

        Fire.firestore().collection('tags').doc(spotId).get()
            .then(spot => {
                const spotData = spot.data()
                if (spotData) {
                    setSpot({ data: spotData, id: spot.id })

                    Fire.firestore().collection('users').doc(spotData.user_id).get()
                        .then(user => {
                            const userData = user.data()
                            setUser(userData)
                        })

                    if (spotData.wall) {
                        const wallIds = Object.keys(spotData.wall)
                        const query = Fire.firestore().collection('walls').where('id', 'in', wallIds)
                        setGetRelatedWallsQuery(query)
                    }

                    if (spotData.user_id == firebase.auth().currentUser?.uid) {
                        setIsCurrentUsersSpot(true)
                    }
                }
            })
    }, [spotId]) // Pass an empty array for the second argument to make sure that useEffect is only actually run once

    const navigate = () => {
        window.open(`https://google.com/maps/dir/?api=1&origin=&destination=${spot.data.location.latitude},${spot.data.location.longitude}`, '_blank')
    }

    const deleteSpot = () => {
        firebase.firestore().collection('tags').doc(spotId).delete().then(() => {
            console.log('Spot Deleted')
            props.history.push('/home')
        }).catch(error => {
            console.error("Error deleting spot: ", error)
        })
    }

    const deleteSpotPressed = () => {
        setShowDeleteModal(true)
    }

    const profileClicked = () => {
        props.history.push(`/profile/${spot.data.user_id}`)
    }

    const EditSpotModal = () => {
        return (
            <div>
                {
                    showEditSpot &&
                    <EditSpot
                        name={spot.data.name}
                        details={spot.data.description}
                        spotId={spotId}
                        showSpot={setShowEditSpot}></EditSpot>
                }

            </div>
        )
    }

    return (

        <div>
            <ShowModal />
            <EditSpotModal />
            {
                showSaveSpotModal && <AddToWall spot={spot} exitButtonPressed={() => setShowSaveSpotModal(false)} />
            }
            {
                !spot.id && <Loading />
            }
            <Modal size="sm" show={showDeleteModal} animation={true} centered>
                <ModalBody>
                    <Col>
                        <span className="font-weight-bold">Are you sure you want to delete this Spot '{spot.data.name}'?</span>
                    </Col>
                    <Col className="mt-3">
                        <Button className="rounded-pill" style={{ backgroundColor: "red", border: "none" }} onClick={deleteSpot}>Delete</Button>
                        <Button className="ml-3 rounded-pill" variant="dark" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    </Col>
                </ModalBody>
            </Modal>
            {
                spot.id &&
                <div>
                    <ChangeViewSwitch />

                    <Container className="mt-5">
                        <Row className="justify-content-md-center mb-5">
                            <Col xs="12" md="6" xl="4">
                                <Container>
                                    <Row>
                                        <Col xl={{ span: 9, offset: 3 }} xs="12" className="p-0" >
                                            <Image className="graffiti-image global-radius" src={spot.data.picture_url} width="300" height="500" />
                                        </Col>
                                    </Row>
                                </Container>

                            </Col>
                            <Col xs="12" md="6" xl="4" className="mt-3">
                                <Container>
                                    <Row className="justify-content-md-center">
                                        <Col xs="12">
                                            <div>
                                                <strong>Share this spot on your social media:</strong>
                                            </div>
                                            <Share name={spot.data.name} url={Utilities.getSpotUrl(spot)} imageUrl={spot.data.picture_url} />
                                        </Col>
                                        <Col xs="12">
                                            <p className="font-weight-bold view-spot-title">{spot.data.name}</p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <FontAwesomeIcon icon={faMapMarkerAlt} /> <span className="font-weight-bold" style={{ textTransform: "capitalize" }}>{spot.data.address}</span>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-3">
                                            <span className="font-weight-bold">For people interested in: </span><span>{Utilities.convertJsonToList(spot.data.hashtags)}</span>
                                        </Col>
                                    </Row>
                                    {/* The profile image for the user who posted this Spot */}
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-3">
                                            <ProfileSection user={user} />
                                        </Col>
                                    </Row>

                                    {/* The address of the Spot */}
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-3">
                                            <p>{spot.data.description != null ? spot.data.description.split('\n').map((item: string) => {
                                                return (
                                                    <p key={uuidv4()}>
                                                        {item}
                                                    </p>
                                                )
                                            }) : ""}</p>
                                        </Col>
                                    </Row>

                                    {/* The Save and Navigate buttons */}
                                    <Row className="justify-content-md-left mt-2">
                                        <Col xs="auto">
                                            <SwitchControl
                                                buttonTitles={isCurrentUsersSpot ? ['Save', 'Navigate', 'Delete', 'Edit'] : ['Save', 'Navigate']}
                                                buttonFunctions={isCurrentUsersSpot ? [save, navigate, deleteSpotPressed, (() => setShowEditSpot(true))] : [save, navigate]}
                                                variants={isCurrentUsersSpot ? ['dark', 'dark', 'dark', 'dark'] : ['dark', 'dark']}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs="12" md="2">

                            </Col>
                            <Col xs="12" style={{ maxWidth: Constants.ContainerMaxWidth }}>
                                <Newsletter />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-left mt-2">
                            <Col xs="auto">
                                <h4>Check out these Walls:</h4>
                            </Col>
                        </Row>
                        <hr color="lightGray"></hr>
                        {
                            getRelatedWallsQuery &&
                            <WallGrid query={getRelatedWallsQuery}></WallGrid>
                        }
                    </Container>
                </div>
            }
        </div>
    )
}

export default ViewSpot;