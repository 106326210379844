import firebase from 'firebase';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Image, Modal, ModalBody, CardColumns, CardDeck } from 'react-bootstrap';
import Editor from 'react-medium-editor'
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from '../../constants';
import useCurrentUser from '../../hooks/useUser';
import StoryObject from '../../models/storyObject';
import '../../index.css'
import Utilities from '../../utilities';
import StorySection from '../../models/storySectionObject';
import SpotGrid from '../../components/spot/spotGrid';
import SpotCard from '../../components/spotCard';
import useFirebaseUser from '../../hooks/useFirebaseUser';
import ProfileSection from '../../components/profileSection';
import useSpot from '../../hooks/useSpot';
import Discussion from '../../components/discussion';
import StoryFeedback from '../../components/storyFeedback';
import { v4 as uuidv4 } from 'uuid'
import Share from '../../components/share';
import Newsletter from '../../components/newsletter';
import MetaTags from 'react-meta-tags'
import ViewSpot from '../../components/spot/viewSpot'
import ViewSpotComponent from '../../components/spot/viewSpotComponent';
// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');


const ViewStory = () => {

    const { storyId } = useParams()

    /** The current user if she's logged in */
    const { currentUser } = useCurrentUser()

    /** Is the story finished loading? */
    const [loaded, setLoaded] = useState(false)

    /** The of the current section that the user is adding a spot to */
    const [addToSection, setAddToSection] = useState<{
        section: StorySection,
        sectionId: string
    }>()

    const history = useHistory()

    /** We give the story this initial state only so that we can display the complete UI without any issues. After the user is verified to be logged in, we update the story object */
    const [story, setStory] = useState<StoryObject>({
        id: storyId ?? "",
        user_id: currentUser?.user_id ?? "",
        isPublished: false,
        storySections: [],
        allSpots: {}
    })

    const { firebaseUserDoc } = useFirebaseUser(story.user_id ?? "")

    const query = firebase.firestore().collection(Constants.Firebase.Tags)

    const { spot } = useSpot(story.mainSpot)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [storyId])

    useEffect(() => {
        if (story) { return }
    }, [currentUser])


    useEffect(() => {
        initialize()
    }, [])

    const initialize = () => {
        firebase.firestore().collection(Constants.Firebase.Stories).doc(storyId).get().then(result => {
            if (!result.exists) {
                setLoaded(true)
                return
            }

            const story = result.data() as StoryObject

            setStory(story)

            setLoaded(true)
        })
    }

    useEffect(() => {
        initialize()
    }, [storyId])

    const shareStoryPressed = () => {
        history.push(`/c/${uuidv4().substring(0, 8)}`)
    }

    const Line = () => {
        return (
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
        )
    }

    return (
        <div>
            {
                story && spot &&
                <MetaTags>
                    <title>{story.title}</title>
                    <meta id={ story.id } name="description" content={ Utilities.removeTags(story.storySections[0].content) } />
                    <meta id={ story.id } property="og:title" content={ Utilities.removeTags(story.title) } />
                    <meta id={ story.id } property="og:image" content={ spot.data.picture_url } />
                    <meta id={ story.id } property="og:canonical" />
                </MetaTags>
            }
            {
                loaded &&
                <div>
                    <Image width="100%" style={{ maxHeight: "500px", objectFit: "cover" }} src={spot?.data.picture_url} />
                    <Container className="mb-5" style={{ maxWidth: Constants.maxWidth }}>
                        <Row>
                            <Col className="my-4">
                                <span style={{ fontSize: "20px" }}>A Graffiti Maps Story</span>
                                {
                                    (currentUser && firebaseUserDoc) && currentUser.user_id == firebaseUserDoc.user_id &&
                                    <Button variant="outline-dark" onClick={() => {
                                        history.push(`/c/${story.id}`)
                                    }} className="rounded-pill ml-2">Edit Story</Button>
                                }
                                <hr />
                            </Col>

                            {
                                story && spot && story.title &&
                                <Col xs="12">
                                    Share this article: <Share name={Utilities.removeTags(story.title)} url={window.location.href} imageUrl={spot.data.picture_url} message={`Check out this article on Graffiti Maps called ${Utilities.removeTags(story.title ?? "")}`} />
                                </Col>

                            }

                            <Col xs="12">
                                <Editor
                                    style={{ outline: "none", fontSize: "35px", fontWeight: "bold" }}
                                    text={story?.title?.trim() ?? ""}
                                    options={{
                                        toolbar: false,
                                        disableEditing: true
                                    }}
                                />
                            </Col>


                            <Col xs="12">
                                {
                                    firebaseUserDoc &&
                                    <div style={{ fontSize: "18px" }} className="mb-3">
                                        <strong>Story written by:</strong>
                                        <div className="my-3">
                                            <ProfileSection user={firebaseUserDoc} />
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>


                        {
                            story.storySections.map((section, index) => {
                                return (
                                    <Row className="justify-content-md-center">
                                        <Col xs="12" className="mt-2">
                                            <Editor
                                                style={{ outline: "none", fontSize: "20px" }}
                                                text={section.content}
                                                options={{
                                                    toolbar: false,
                                                    disableEditing: true,
                                                    placeholder: {
                                                        text: ""
                                                    }
                                                }}
                                            />
                                        </Col>
                                        {
                                            section.spots.length > 0 &&
                                            <Col xs="12" className="my-3" style={{ margin: "auto" }}>
                                                <CardDeck>
                                                    {
                                                        section.spots.map(spotId => {
                                                            return (
                                                                <ViewSpotComponent spotId={spotId} />                                                                                                                           
                                                            )
                                                        })
                                                    }
                                                </CardDeck>
                                            </Col>
                                        }
                                    </Row>
                                )
                            })
                        }                        

                        <Line />
                        <Row>
                            <Col>
                                <div className="mb-2 text-center">
                                    <Button className="rounded-pill" variant="outline-dark" onClick={shareStoryPressed} >+ Share Your Own Story NOW</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Newsletter />
                            </Col>
                        </Row>

                        <Line />
                        <Row>
                            <Col>
                                <Discussion story={story} />
                            </Col>
                        </Row>
                        <Line />
                    </Container>

                    <Container>
                        <Row>
                            <Col>
                                {
                                    !currentUser &&
                                    <div>
                                        <SpotGrid expanded={true} query={query} blurred={false} />
                                    </div>
                                }
                                {
                                    currentUser &&
                                    <SpotGrid expanded={true} query={query} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

            }

        </div >

    )
}

export default ViewStory