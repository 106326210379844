import React, { useState, useEffect, useRef } from 'react'
import SpotGrid from '../../components/spot/spotGrid'
import { useHistory, withRouter } from 'react-router-dom'
import ChangeViewSwitch from '../../components/navbar/changeViewSwitch'
import Fire from '../../fire'
import { Container, Row, Col, Image, Button, CardColumns } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import Header from '../../components/header'
import ShareWalls from '../../components/shareWalls'
import UseHashtags from '../../components/useHashtags'
import NeverForget from '../../components/neverForget'
import SignUp from '../signup/signup'
import TestimonialsList from '../../components/testimonials/testimonialsList'
import { Constants } from '../../constants'
import useStories from '../../hooks/useStories'
import firebase from 'firebase'
import StoryCard from '../stories/storyCard'

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

const Home = (props: any) => {

    const [redirect, setRedirect] = useState("")

    const { stories } = useStories(firebase.firestore().collection(Constants.Firebase.Stories).where(Constants.Firebase.IsPublished, '==', true).limit(30))

    /** A reference to the ReactPlayer so that we can control it within this page */
    const ref = useRef<ReactPlayer>(null)
    const storiesRef = useRef(null)
    const history = useHistory()

    const executeScroll = () => {
        scrollToRef(storiesRef)
    }

    const getAllSpotsQuery = Fire.firestore().collection('tags').limit(24).orderBy('time_added', 'desc')

    return (
        <div>
            <Container className="my-3">
                <Row className="text-center">
                    <Col>
                        <Button className="rounded-pill" variant="outline-dark" onClick={executeScroll}>See latest Stories</Button>
                    </Col>
                </Row>
                <hr />
            </Container>

            <Header />

            <SignUp />

            <UseHashtags />

            <ShareWalls />

            <NeverForget />

            <hr />

            <div ref={storiesRef}>
                {
                    stories &&
                    <Container style={{ marginTop: "30px", maxWidth: "1000px" }} className="my-5">

                        <Row className="text-center">
                            <Col>
                                <div style={{ fontSize: "55px" }}>
                                    <strong>Most recent stories told by our users</strong>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <CardColumns style={{ maxWidth: "1000px" }}>
                                    {
                                        stories.map(story => {
                                            return (
                                                <StoryCard id={story.id} key={story.id} action={[{
                                                    text: "Read Story",
                                                    callback: () => {
                                                        history.push(`/s/${story.id}`)
                                                    }
                                                }]
                                                } />
                                            )
                                        })
                                    }
                                </CardColumns>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>

            <hr></hr>

            <TestimonialsList testimonials={Constants.testimonials} />

            <Container>
                <div className="my-5">
                    <Row className="text-center" >
                        <Col>
                            <hr></hr>
                            <div style={{ fontSize: "55px" }}>
                                <strong>Check out this short video on the value of Graffiti</strong>
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col className="d-flex justify-content-center">
                            <ReactPlayer
                                style={{ borderColor: "black", borderWidth: "3px" }}
                                url='https://vimeo.com/460784893'
                                ref={ref}
                                playsinline={true}
                                controls={true}
                                config={
                                    {
                                        file: { forceHLS: true }
                                    }
                                } />
                        </Col>
                    </Row>
                </div>
            </Container>
            <Row>
                <Col className="d-flex justify-content-center my-2" >
                    <Image src="/download.png" width="300px" height="100px" onClick={() => window.location.href = 'https://apps.apple.com/gb/app/graffiti-social-location/id1383348851'} ></Image>
                </Col>
            </Row>
            <hr />

            <Container style={{ marginTop: "30px" }} className="my-5">
                <Row className="text-center">
                    <Col>
                        <div style={{ fontSize: "55px" }}>
                            <strong>Most recent spots on Graffiti Maps</strong>
                        </div>
                    </Col>
                </Row>
            </Container>

            <ChangeViewSwitch />
            <SpotGrid showLoading={false} query={getAllSpotsQuery} />
        </div>


    )
}

export default withRouter(Home)