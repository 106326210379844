import React from 'react'
import { Col, Container, Row, Image, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ShareStoryButton from './shareStoryComponent'
import { v4 as uuidv4 } from 'uuid'
import StoryCard from '../pages/stories/storyCard'
import MockObjects from '../objects/mockObjects'

const NeverForget = () => {

    const history = useHistory()

    return (
        <div>
            <hr></hr>
            <Container className="my-5">
                <Row className="my-5">
                    <Col xs="12" md="6">
                        <h1 style={{ fontSize: "45px", fontWeight: "bold" }} >
                            DON'T LOSE THIS CHANCE to BE FIRST on something NEW!
                        </h1>
                        <div className="mt-5" style={{ fontSize: "30px", fontWeight: "bold" }}>
                            <p>Don't wait and watch again as other travel bloggers get all the attention 😢 and make all the money.</p>

                            <p>There are still spots left to be one of the FOUNDING MEMBERS on Graffiti.</p>

                            <p>To get started, Create your account RIGHT NOW and FINISH WRITING YOUR FIRST AMAZING TRAVEL STORY.</p>

                            <p>You have EVERYTHING TO GAIN and SO LITTLE TO LOSE.</p>

                            <p>We can't wait to read your FIRST amazing travel story.</p>
                        </div>
                        <div>
                            <Button className="rounded-pill mb-4" style={{ width: "100%", fontSize: "20px" }} variant="info" onClick={() => history.push(`/c/${uuidv4().substring(0, 8)}`)} ><strong>+ Start Writing NOW</strong></Button>
                        </div>
                    </Col>
                    <Col xs="12" md="6" className="d-flex justify-content-center">
                        <div style={{ maxWidth: "400px" }}>
                            <StoryCard id="WhyuseGraffitiMapsforyourtravelblog-8d7365e0"
                                mockStory={ { story: MockObjects.whyUseGraffiti, imageUrl: '/whyusegraffiti.jpg' } }
                                action={[{
                                    text: "Read Story",
                                    callback: () => {
                                        history.push(`/s/${"WhyuseGraffitiMapsforyourtravelblog-8d7365e0"}`)
                                    }
                                }]
                                } />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NeverForget