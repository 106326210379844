import React from 'react'
import WallGrid from './wallGrid'
import ChangeViewSwitch from '../../components/navbar/changeViewSwitch'

const Walls = () => {
    return (
        <div>
            <ChangeViewSwitch variants={['light', 'dark']} />
            <hr />
            
            <WallGrid />
        </div>
    )
}

export default Walls