import React, { useEffect, useState, useRef } from 'react'

import Fire from '../../fire'
import SpotObject from './spotObject'
import { Container, Row, Col, CardColumns, CardDeck, CardGroup } from 'react-bootstrap'

import { firestore } from 'firebase'
import AddToWall from '../wall/addToWall'
import Loading from '../loading/loading'

import '../../App.css'
import useCurrentUser from '../../hooks/useUser'
import ShowLogin from '../showLogin'
import SpotCard from '../../components/spotCard'
import { v4 as uuidv4 } from 'uuid'

interface IState {
    spots: SpotObject[]
}

/**
 * Displays a grid of spots
 * @param props 
 */
const SpotGrid = (props: any) => {

    const [lastSpot, _setLastSpot] = useState<firestore.DocumentData | undefined>(undefined)

    const [spotSets, _setSpotsSets] = useState<Record<string, SpotObject[]>>({})

    /** 
     * Since we're using an event listener, when accessing the state objects from within the event listener, 
     * the initial state will always be returned.
     * We create these references so that we can always make sure that we have access to the latest state objects
     * For more information see: https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
     */
    const lastSpotRef = useRef(lastSpot)
    const spotsRef = useRef(spotSets)

    const updateLastSpot = (snapshot: firestore.DocumentData) => {
        lastSpotRef.current = snapshot
        _setLastSpot(snapshot)
    }

    const updateSpots = (spots: SpotObject[]) => {

        if (!spotsRef.current) { return }

        const setId = uuidv4()
        spotsRef.current[setId] = spots
        const mySpotSets = spotSets
        mySpotSets[setId] = spots
        _setSpotsSets(mySpotSets)
    }

    const [showSaveSpotModal, setShowSaveSpotModal] = useState(false)
    const [selectedSpotToSave, setSelectedSpotToSave] = useState<SpotObject | undefined>(undefined)

    const [allowRetrieveMoreSpots, setAllowRetrieveMoreSpots] = useState<Boolean | null>(null)
    const getMoreSpotsRef = useRef(allowRetrieveMoreSpots)

    const [finished, setFinished] = useState(false)

    const updateAllowRetrieveMoreSpots = (value: Boolean | null) => {
        getMoreSpotsRef.current = value
        setAllowRetrieveMoreSpots(value)
    }

    /**The number of Spots to download at one time */
    const spotDownloadLimit = props.maxSize ?? 10
    const [processing, setProcessing] = useState(false)

    /** Whether or not to show login */
    const [showLogin, setShowLogin] = useState(false)

    /** Get the current user */
    const { currentUser } = useCurrentUser()




    /**
     * Checks to see if the user has reached the bottom of the given element
     * @param element Element to check if has reached bottom of
     */
    const isBottom = (element: HTMLElement) => {
        return element.getBoundingClientRect().bottom <= window.innerHeight + 300
    }

    /** Called every time the user scrolls */
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('allSpotsGrid');
        // Sometimes this function is randomly run at the initial rendering of the page
        // We know for sure that lastSpot is not set until all the spots are initially downloaded from the database.
        // So by checking to see if there is a value in lastSpot, we make sure that we don't start a duplicate download of spots.
        if (wrappedElement && lastSpotRef.current) {
            if (isBottom(wrappedElement)) {
                if (!props.maxSize) {
                    updateAllowRetrieveMoreSpots(true)
                }
            }
        }
    }

    useEffect(() => {

        if (!getMoreSpotsRef.current) {
            return
        }

        if (getMoreSpotsRef.current) {
            getSpotsFromFirebase(spotDownloadLimit)
        }

    }, [allowRetrieveMoreSpots])

    useEffect(() => {
        if (lastSpot) {
            return
        }

        document.addEventListener('scroll', trackScrolling)        
        getSpotsFromFirebase(spotDownloadLimit)

    }, [])

    /** Whenever the selected spot changes we  */
    useEffect(() => {
        if (selectedSpotToSave) {
            // Show the walls modal
            setShowSaveSpotModal(true)
        } else {
            setShowSaveSpotModal(false)
        }
    }, [selectedSpotToSave])

    /**
     * Get a list of spots from Firestore
     * @param limit The number of Spots to get at one time
     */
    const getSpotsFromFirebase = async (limit: number) => {

        // If we've already retrieved the initial values and now we're getting the next set of values
        const lastSpot = lastSpotRef.current

        if (lastSpot) {
            const query: firestore.Query<firestore.DocumentData> = props.query
            let startAtSnapshot = query != null ? query.startAfter(lastSpot) : Fire.firestore().collection('tags').orderBy('time_added', 'desc').startAfter(lastSpot)
            setFinished(true)

            if (limit !== 0) {
                startAtSnapshot = startAtSnapshot.limit(limit)
            }

            startAtSnapshot.get().then(snapshot => {
                console.log("Retrieved snapshot")
                updateStateUsingSnapshot(snapshot)
                updateLastSpot(snapshot.docs[snapshot.docs.length - 1])
                updateAllowRetrieveMoreSpots(false)
            })

            setProcessing(false)
            return
        }

        if (finished) {
            return
        }

        setProcessing(true)

        let query: firestore.Query = props.query

        // if this is the initial retrieval of the spots

        if (limit !== 0) { query = query.limit(limit) }

        const snapshot = await query.get()
        updateStateUsingSnapshot(snapshot)
        if (props.setSpotCount) {
            props.setSpotCount(snapshot.docs.length)
        }

        setProcessing(false)
        updateLastSpot(snapshot.docs[snapshot.docs.length - 1])
        updateAllowRetrieveMoreSpots(false)
    }

    /**
     * Update the state with the spots that have been downloaded
     * @param snapshot The snapshot of the query to retrieve spots from the Firebase
     */
    const updateStateUsingSnapshot = (snapshot: firestore.QuerySnapshot) => {

        const nonReportedSpots = snapshot.docs.filter((doc) => doc.data().reported_reason === undefined && doc.data().is_private !== true)

        let downloadedSpots = nonReportedSpots.map(doc => {
            return {
                data: doc.data(),
                id: doc.id
            } as SpotObject
        })

        // downloadedSpots = spotsRef.current.concat(downloadedSpots)
        updateSpots(downloadedSpots)
    }

    /**
     * User presses the Save button on a spot
     * @param spot The spot that the user is saving
     */
    const saveClicked = (spot: SpotObject) => {
        if (!currentUser) {
            setShowLogin(true)
            return
        }

        setSelectedSpotToSave(spot)
    }

    const navigate = (spot: SpotObject) => {
        window.open(`https://google.com/maps/dir/?api=1&origin=&destination=${spot.data.location.latitude},${spot.data.location.longitude}`, '_blank')
    }

    return (

        <div>
            <ShowLogin show={showLogin} />
            {
                showSaveSpotModal && <AddToWall spot={selectedSpotToSave} exitButtonPressed={() => setSelectedSpotToSave(undefined)} />
            }
            {
                processing && props.showLoading !== false && <Loading />
            }
            <Container id="allSpotsGrid" className="mt-2" style={{ maxWidth: "1000px" }}>
                <Row>
                    {
                        <Col>
                            {
                                Object.keys(spotSets).map(spotSetId => {
                                    return (
                                        <CardColumns key={spotSetId}>
                                            {
                                                spotSets[spotSetId].map(spot => {

                                                    return (
                                                        <SpotCard id={spot.id} key={spot.data.last_modified_time ?? spot.id} spotObject={spot}  />
                                                    )
                                                })
                                            }
                                        </CardColumns>
                                    )
                                })
                            }

                        </Col>
                    }
                    {
                        // props.expanded && spots.map((spot: SpotObject, index) => {
                        //     return (
                        //         <div>
                        //             {
                        //                 props.blurred &&
                        //                 <div className="hidden-content">
                        //                     <ViewSpotComponent spot={spot} />
                        //                     <hr color="lightGray" />
                        //                 </div>
                        //             }
                        //             {
                        //                 !props.blurred &&
                        //                 <div>
                        //                     <ViewSpotComponent spot={spot} />
                        //                     <hr color="lightGray" />
                        //                 </div>
                        //             }
                        //         </div>
                        //     )
                        // })
                    }
                </Row>
            </Container>
        </div>

    )

}

export default SpotGrid;