import React, { useEffect, useState } from 'react'
import { CardColumns } from 'react-bootstrap'
import useSearchTimer from '../hooks/useSearchTimer'
import SimpleInput from './form/input'
import SpotData from './spot/spotData'
import SpotObject from './spot/spotObject'
import SpotCard from './spotCard'

const SpotCardGrid = (props: {
    query?: firebase.firestore.Query<firebase.firestore.DocumentData>,
    action: [{
        text: string,
        callback: any
    }],
    spots?: SpotObject[]
}) => {

    const [spots, setSpots] = useState<SpotObject[]>(props.spots ?? [])

    const [allSpots, setAllSpots] = useState<SpotObject[]>([])

    const [searchText, setSearchText] = useState("")

    useSearchTimer(searchText, (search:string) => {

        if (!search || search.trim() === "") {
            setSpots(allSpots)
            return
        }

        const mySpots = allSpots.filter(s =>
            s.data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
            || s.data.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
            || Object.keys(spots).indexOf(search.toLowerCase()) !== -1
            || s.data.address.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )

        setSpots(mySpots)
    })

    useEffect(() => {
        if (!props.spots) { return }
        setSpots(props.spots)
    }, [props.spots])

    useEffect(() => {
        if (props.spots) { 
            setAllSpots(props.spots)
            return 
        }
        if (!props.query) { return }
        props.query.get().then(result => {
            if (result.empty) { return }

            const spots = result.docs.map(doc => {
                const spotObject: SpotObject = {
                    data: doc.data() as SpotData,
                    id: doc.id
                }

                return spotObject

            })
            setSpots(spots)
            setAllSpots(spots)
        })

    }, [])

    return (
        <div className="my-4">
            <SimpleInput        
                placeholder="Search for a spot"
                header={true}
                onChange={setSearchText} />

            <CardColumns>
                {
                    spots.map(spot => {
                        return (
                            <SpotCard id={ spot.id } key={ spot.id } spotObject={ spot } action={props.action} />
                        )
                    })
                }
            </CardColumns>
        </div>
    )

}

export default SpotCardGrid