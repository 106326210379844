import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import SpotData from '../components/spot/spotData'
import SpotObject from '../components/spot/spotObject'
import { Constants } from '../constants'

const useSpot = (id?: string ) => {

    const [spot, setSpot] = useState<SpotObject>()

    useEffect(() => {        

        if (!id) { return }

        firebase.firestore().collection(Constants.Firebase.Tags).doc(id).get().then((result => {
            if (!result.exists) { return }

            const spotObject: SpotObject = {
                data: result.data() as SpotData,
                id: result.id
            }

            const spot = spotObject
            setSpot(spot)
        }))
    }, [id])

    return { spot }

}

export default useSpot