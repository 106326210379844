import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import SpotData from '../components/spot/spotData'
import SpotObject from '../components/spot/spotObject'
import { Constants } from '../constants'
import useCurrentUser from './useUser'

const useCurrentUsersSpots = () => {

    const [spots, setSpots] = useState<SpotObject[]>()

    const { currentUser } = useCurrentUser()

    useEffect(() => {  
        if (!currentUser) { return }
        
        firebase.firestore().collection(Constants.Firebase.Tags).where( Constants.Firebase.UserId, '==', currentUser.user_id ).get().then(result => {
            if (result.empty) { return }

            const spots = result.docs.map(doc => {
                const spotObject: SpotObject = {
                    data: doc.data() as SpotData,
                    id: doc.id
                }

                return spotObject

            })
            
            setSpots(spots)            
        })
    }, [currentUser])

    return { spots }
}

export default useCurrentUsersSpots