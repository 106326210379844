import React, { useState } from 'react'
import { Container, Row, Col, Modal, ModalBody, Button } from 'react-bootstrap'
import SimpleTextArea from '../form/textArea'
import SimpleInput from '../form/input'
import firebase from 'firebase'
import Utilities from '../../utilities'
import SwitchControl from '../switch-control/switch-control'

const EditSpot = (props: {
    details: string,
    name: string,
    spotId?: string,
    showSpot: any
}) => {

    const [spotDetails, setSpotDetails] = useState(props.details)
    const [name, setName] = useState(props.name)

    const saveSpot = () => {

        if (!props.spotId) {
            props.showSpot(false)
            return
        }

        // Get all the hashtags from the spot details
        const hashtagObject = Utilities.getHashtagObjectFromString(spotDetails)
        // save the hashtags to Firebase
        Utilities.saveHashtagsToFirebase(hashtagObject)

        firebase.firestore().collection("tags").doc(props.spotId).update({
            description: spotDetails,
            name: name,
            hashtags: hashtagObject
        }).then(_ => {
            window.location.reload(false)
            props.showSpot(false)            
        }).catch(error => {
            console.log(error)
            props.showSpot(false)
        })
    }

    return (
        <Modal show={true} animation={true} centered>
            <ModalBody>
                <Container>
                    <Row>
                        <Col xs="12" className="mt-3">
                            <h3>Edit Your Spot</h3>
                        </Col>
                        <Col xs="12" className="mt-3">
                            <SimpleInput
                                onChange={setName}
                                placeholder="What do you call this Spot?"
                                value={name}
                                customClassName="d-flex align-items-center" />
                        </Col>
                        <Col xs="12" className="mt-3">
                            <SimpleTextArea
                                onChange={setSpotDetails}
                                placeholder="Enter some details about this place..."
                                value={spotDetails}
                                customClassName="d-flex align-items-center" />
                        </Col>
                        <Col xs="12">
                            {/* <Button variant="dark" className="rounded-pill" onClick={saveSpot}>Save</Button> */}
                            <SwitchControl
                                buttonTitles={['Save', 'Cancel']}
                                buttonFunctions={[saveSpot, (() => props.showSpot(false)) ]}
                                variants={ ['dark', 'dark'] }
                            />
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    )
}

export default EditSpot