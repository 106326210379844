import React, { useEffect, useState } from 'react'
import { Constants } from '../constants'
import StoryObject from '../models/storyObject'

const useStories = (query?: firebase.firestore.Query<firebase.firestore.DocumentData>) => {

    const [stories, setStories] = useState<StoryObject[]>()

    useEffect(() => {
        if (!query) { return }
        query.get().then(result => {
            if (result.empty) { 
                setStories([])
                return 
            }
            const myStories = result.docs.map(doc => doc.data() as StoryObject)
            setStories(myStories)            
        })

    }, [query])

    return { stories }

}

export default useStories