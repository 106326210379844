import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import React, { useEffect, useRef } from 'react';
import SimpleTextArea from '../form/textArea';
import { PlaceDetails } from './placeDetails';

const PlacesAutocomplete = (props:any) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {  },
    debounce: 300
  });

  const registerRef = useRef(null)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useOnclickOutside(registerRef, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (value:any) => {
    // Update the keyword of the input element
    setValue(value);
    props.handleInputChanged(value)
  };

  const handleSelect = (selectedPlace:any) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(selectedPlace.description, false);
    clearSuggestions();

    const placeDetails = new PlaceDetails()
    placeDetails.getPlaceDetails(selectedPlace.place_id).then(details => {
      details.address = selectedPlace.description
      props.spotLocationRetrieved(details)
    })
  };

  const renderSuggestions = () =>
    
    data.map(suggestion => {

      const {
        id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={id}
          onClick={() => handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={registerRef}>
      <SimpleTextArea
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter the Spot's name or address?"
        height={70}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete