import React, { useEffect, useState } from 'react'
import SpotGrid from '../spot/spotGrid'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { firestore } from 'firebase'
import FollowButton from '../followButton/followButton'
import Share from '../share'
import Utilities from '../../utilities'
import WallObject from './wallObject'
import firebase from 'firebase'
import Newsletter from '../newsletter'
import { Constants } from '../../constants'

const SpotsOnWall = (props: any) => {

    const { wallId, wallName, expanded } = useParams()
    const [didMount, setDidMount] = useState(false)

    const [imageUrl, setImageUrl] = useState("")

    const [isExpanded, setIsExpanded] = useState(expanded)

    const getSpotsForWallQuery = firestore().collection('tags').where(`wall.${wallId}`, '==', true)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (didMount) {
            window.location.reload()
        }

        firebase.firestore().collection('walls').doc(wallId).get().then(result => {
            if (result.exists) {
                setImageUrl(result.data()?.fullSizeImageUrl)
            }
        })

        setDidMount(true)
    }, [wallId])

    return (
        <div>
            <Container style={{ maxWidth: Constants.ContainerMaxWidth }} className="my-3">
                <Row>
                    <Col xs="12">
                        <h1 style={{ marginBottom: "-8px" }}>
                            {`${wallName} Wall`}
                        </h1>
                    </Col>
                    
                    <Col xs="12" className="mt-4">
                        {
                            imageUrl &&
                            <div className="mb-4">
                                <div>
                                    <strong>Share this list of spots on your social media</strong>
                                </div>
                                <Share name={wallName as string} url={Utilities.getWallUrl(wallId as string, wallName as string)} imageUrl={imageUrl} />
                            </div>
                        }
                    </Col>
                    <Col xs="12">
                        <FollowButton id={wallId} />
                    </Col>                                        
                </Row>
            </Container>
            <Container style={{ maxWidth: Constants.ContainerMaxWidth }}>
                <Row>
                    <Col>
                        <Newsletter />
                    </Col>
                </Row>
            </Container>
            <SpotGrid expanded={isExpanded} query={getSpotsForWallQuery} wallId={wallId} />
            <Container style={{ maxWidth: Constants.ContainerMaxWidth }}>
                <Row>
                    <Col>
                        <Newsletter />
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default SpotsOnWall