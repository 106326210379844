import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import User from '../models/user'

const useFirebaseUser = (userId: string ) => {

    const [ firebaseUserDoc, setFirebaseUserDoc ] = useState<User>()

    useEffect(() => {
        if (userId == "") { return }
        getUser()
    }, [userId])

    const getUser = async () => {
        if (!userId) { return }
        const userDoc = await firebase.firestore().collection('users').doc(userId).get()
        if (userDoc.exists) {
            setFirebaseUserDoc(userDoc.data() as User)
        }
    }

    return { firebaseUserDoc }

}

export default useFirebaseUser