import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Constants } from '../constants'
import User from '../models/user'

const useUserFromName = (username: string ) => {

    const [ firebaseUserDoc, setFirebaseUserDoc ] = useState<User>()

    useEffect(() => {
        if (username == "") { return }
        getUser()
    }, [username])

    const getUser = async () => {
        if (!username) { return }
        const userDoc = await firebase.firestore().collection('users').where(Constants.Firebase.User.CanonicalUsername, '==', username.toLowerCase().split('_').join(' ')).get()
        if (!userDoc.empty) {
            setFirebaseUserDoc(userDoc.docs[0].data() as User)
        }
    }

    return { firebaseUserDoc }

}

export default useUserFromName