import TestimonialObject from "./models/testimonialObject"

export class Constants {

    public static ContainerMaxWidth = "1000px"

    public static Firebase = {
        User: {
            Username: "username",
            CanonicalUsername: "canonical_username"   
        },
        TipsCollection: "tips",
        EmailsCollection: "emails",
        CommentsField: "comments",        
        Stories: 'stories',
        Drafts: 'storyDrafts',
        UsersCollection: 'users',
        Tags: 'tags',
        UserId: 'user_id',
        IsPublished: 'isPublished',
        Log: 'log',
        Walls: 'walls'
    }

    public static UrlSections = {
        ViewSpot: "viewspot",
        Wall: "wall"
    }

    public static Colors = {
        Tan: '#f2ede2',
        BackgroundColor: 'white',
        Turquoise: "#17b7c1",
        Red: "#db4a25"
    }

    public static HomePageImages = { 
        borderRadius: "15px", 
        width:"80%" 
    }

    public static HomePageTitle = {
        fontSize: "45px", 
        fontWeight: "bold"
    }

    public static maxWidth = "900px"

    public static story = {
        lineHeight: "32px",
        fontSize: "21px"
    }
    
    public static testimonials:TestimonialObject[] = [{
        username: "bluenaxela",
        title: "Beautifully executed and designed, but currently lacks a user base",
        content: "This is a great app to find and save cool places nearby to share with friends or plan out trips. It has Chinese support too which is a plus, but it still needs some major revisions on that end.  The concept is great too, you can very easily organize and save cool and unique places. I used it as an easy point of reference to save the places I really loved.  However the biggest problem is that there aren't many users.  Once more people join then it'll be a fantastic app with lots of great, curated content. Overall it's a great app."
    }, {
        username: "pijidakinro",
        title: "Great app!",
        content: "I love this app because you can tell others about great places to visit when they visit those places.  That way when others visit those same countries or cities, they already know great places to go without having to ask locals who may not speak their language.  I also like the spots appearing on the map. That way you can have an overview of locations, can click the location you're visiting & all the great locations recommended by others, show up. Now, all you have to do is go to them yourself."
    }, {
        username: "msiji",
        title: "Great Way to Remember Hot Spots!",
        content: "Love this app! It's not just a regular social media app where you just look at cool photos and interesting topics. It's actually beneficial for YOU! It helps you remember places that you loved and want to go back to. Or you can see cool spots that others have liked and check them out for yourself. Only thing that could make it better is a reminder to use the app when I'm in a new place. That way absent minded people like me won't have to go back to the spot to post it 😆"
    }, {
        username: "Jshsyshw2937",
        title: "The perfect app for travelers!",
        content: "This app needs more attention! It is so well designed, really user-friendly and easy to navigate I've used it before while traveling and I strongly recommend it for others!"
    }, {
        username: "modest_muscles_fit",
        title: "So simple!!!",
        content: "I love how this app is so simple yet so much fun! You can have followers and follow others. Share your locations. Save others locations as a place that you might want to visit. I'm barely scratching the surface on this app but I absolutely love it 🔥🔥💯🙌🏽"
    }, {
        username: "canelazucar",
        title: "Finally!!",
        content: "What an epic concept that combines the simplicity and ease of sharing a photo on social media with the information output of a worldwide guidebook! I definitely look forward to using this in future worldwide travels. 👍🏾👍🏾"
    }, {
        username: "TCR96ynwa",
        title: "Great app!",
        content: "Such a great idea, makes it easier to find and keep track of cool spots you like!"
    }]

}

