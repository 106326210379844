import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Card, CardColumns, Container } from 'react-bootstrap'
import WallObject from '../../components/wall/wallObject'
import { Constants } from '../../constants'
import '../main/main.css'

const MainPage = () => {

    const [walls, setWalls] = useState([] as WallObject[])

    // Called immediately
    useEffect(() => {
        firebase.firestore().collection(Constants.Firebase.Walls).limit(100).get()
            .then(snapshot => {
                setWalls(getWallsFromSnapshot(snapshot))
            })
    }, []) // By passing an empty array as the second argument of useEffect we ensure that useEffect is only called once

    const getWallsFromSnapshot = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): WallObject[] => {
        const walls = snapshot.docs.map(doc => {
            return {
                data: doc.data(),
                id: doc.id
            } as WallObject
        })

        return walls
    }

    return (
        <Container className="square">
            <CardColumns>
                {
                    walls &&
                    walls.map(wall => {
                        return (
                            <div style={{ position: "relative" }} >
                                <Card>
                                    <Card.Img key={wall.id} src={wall.data.fullSizeImageUrl} />
                                </Card>                                
                            </div>
                        )
                    })
                }
            </CardColumns>
        </Container>
    )

}

export default MainPage