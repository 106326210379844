import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Modal, ModalBody } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SwitchControl from '../switch-control/switch-control'
import '../../global.css'
import firebase from 'firebase'
import SignIn from '../../pages/signIn/signIn'

// Display a wall.
const Wall = (props: any) => {

    const [user, setUser] = useState({} as any)
    const [isFollowing, setIsFollowing] = useState(false)

    const [showLoginModal, setShowLoginModal] = useState(false)

    const ShowModal = () => {
        return (
            <div>
                {
                    showLoginModal &&
                    <Modal size="sm" show={true} animation={true} centered>
                        <ModalBody>
                            <SignIn close={() => setShowLoginModal(false)} />
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }    

    useEffect(() => {        
        const userId = firebase.auth().currentUser?.uid
        if (userId) {
            firebase.firestore().collection('users').doc(userId).get().then(snapshot => {
                const user = snapshot.data()
                if (user) {
                    setUser(user)
                    if (user.wallsFollowing) {
                        if (Object.keys(user.wallsFollowing).indexOf(props.id) !== -1) {
                            setIsFollowing(true)
                        }
                    }
                }
            })
        }

    }, [])

    // Follow the wall by adding a key value pair to the user object
    const follow = () => {

        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            const userId = firebase.auth().currentUser?.uid
            if (userId) {
                firebase.firestore().collection('users').doc(userId).update({
                    [`wallsFollowing.${props.id}`]: true
                })
                firebase.firestore().collection('walls').doc(props.id).update({
                    [`followers.${userId}`]: true
                })
                setIsFollowing(true)
            }
        }
    }

    // Unfollow the wall by deleting its key value pair from the user object
    const unFollow = () => {
        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            const userId = firebase.auth().currentUser?.uid
            if (userId) {
                firebase.firestore().collection('users').doc(userId).update({
                    [`wallsFollowing.${props.id}`]: firebase.firestore.FieldValue.delete()
                })
                firebase.firestore().collection('walls').doc(props.id).update({
                    [`followers.${userId}`]: firebase.firestore.FieldValue.delete()
                })
                setIsFollowing(false)
            }
        }
    }

    return (
        <div>
            <ShowModal />
            <Container>
                <Row>
                    <Col
                        className="grid-margin-padding" xs="12">
                        {/* Take the user to the selected spot when clicked on */}
                        <Link target="_blank" to={`/wall/${props.id}/${props.title}`}>
                            {/* The main image for the wall */}
                            <Image className="graffiti-image global-radius" src={props.imageUrl} height="300" />
                        </Link>
                    </Col>
                    <Col>
                        <div className="font-weight-bold" style={{ fontSize: "25px" }} >{props.title}</div>
                    </Col>
                    <Col xs="12">
                        <SwitchControl
                            buttonTitles={[isFollowing ? "Unfollow" : "Follow"]}
                            buttonFunctions={[isFollowing ? unFollow : follow]}
                            variants={['dark']}
                            customClassName="test"
                            ></SwitchControl >
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Wall