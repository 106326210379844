import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import exifr from 'exifr'
import Geocode from 'react-geocode'
import { PlaceDetails } from '../autocomplete/placeDetails'

const AddImageInput = (props:any) => {

    const fileInputRef = useRef(null)

    useEffect(() => {
        Geocode.setApiKey("AIzaSyAZvESPWc0gv9xQq4WvFITUE0yT4w0fAso")
        Geocode.setLanguage("en")
        Geocode.enableDebug()
    }, [])

    /**
     * When the user clicks the add image button
     */
    const addImageClicked = () => {
        
        const fileInput = fileInputRef.current as any
        if (fileInput) {
            fileInput.value = ""
            fileInput.click()
        }
    }

    const onChange = async (evt: any) => {
        const files = evt.target.files

        const latLong = await exifr.gps(files[0])
            if (latLong) {
                Geocode.fromLatLng(latLong.latitude.toString(), latLong.longitude.toString()).then(async response => {
                    const details = response.results[0]

                    const placeDetails = new PlaceDetails()
                    const spotLocation = placeDetails.getPlaceObject(details)
                    
                    spotLocation.address = details.formatted_address
                    props.spotLocationRetrieved(spotLocation)
                    console.log(spotLocation)
                },
                    error => {
                        console.error(error)
                    })
            }

        console.log(props)
        props.onChange(files)
    }

    return (
        <span>
            <Button onClick={addImageClicked} className="rounded-pill" variant={props.variant ?? 'dark'}>{props.caption ?? 'Add Image'}</Button>
            <input
                style={{ display: 'none' }} // Don't show this input
                ref={fileInputRef}
                className="FileInput"
                type="file"
                accept="image/*"                
                onChange={onChange} />
        </span>
    )

}

export default AddImageInput