import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Newsletter from './newsletter'
import ShareStoryButton from './shareStoryComponent'

const Header = () => {

    const history = useHistory()

    return (
        <div style={{ width: "100%" }} className="text-center">
            <Container>
                <Row>
                    <Col xs="12" md="6" className="d-flex align-items-center text-left">
                        <div >
                            <h1 style={{ fontSize: "45px", fontWeight: "bold" }} >
                                Graffiti is the FIRST Travel Blogging Platform in the world with TRAVEL MAPS BUILT-IN!
                                </h1>
                            <div style={{ fontSize: "20px" }} className="text-left mt-2" >

                                <p>As a travel blogger, you help your readers FIND NEW LOCATIONS all over the world.</p>

                                <p>But what if you could make it REALLY EASY for your readers to follow your every move and FIND EVERY AMAZING SPOT in your stories?</p>

                                <p>GRAFFITI MAPS is the FIRST Platform in the world to do EXACTLY this!</p>
                            </div>
                            <ShareStoryButton />
                            <Newsletter />
                        </div>
                        
                    </Col>


                    <Col xs="12" md="6" className="my-4 d-flex justify-content-center">
                        <Image src="/main-screen.png" height="700" />
                    </Col>
                </Row>
            </Container>
            <hr />
        </div >
    )
}

export default Header