import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, Button } from 'react-bootstrap'
import SignIn from '../../pages/signIn/signIn'
import firebase from 'firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faStar } from '@fortawesome/free-solid-svg-icons'

const FollowButton = (props: any) => {

    const [isFollowing, setIsFollowing] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)

    const ShowModal = () => {
        return (
            <span>
                {
                    showLoginModal &&
                    <Modal size="sm" show={true} animation={true} centered>
                        <ModalBody>
                            <SignIn close={() => setShowLoginModal(false)} />
                        </ModalBody>
                    </Modal>
                }
            </span>
        )
    }

    useEffect(() => {

        const userId = firebase.auth().currentUser?.uid
        if (userId) {
            firebase.firestore().collection('users').doc(userId).get().then(snapshot => {
                const user = snapshot.data()
                if (user) {
                    if (user.wallsFollowing) {
                        if (Object.keys(user.wallsFollowing).indexOf(props.id) != -1) {
                            setIsFollowing(true)
                        }
                    }
                }
            })
        }

    }, [])

    // Follow the wall by adding a key value pair to the user object
    const follow = () => {

        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            const userId = firebase.auth().currentUser?.uid
            if (userId) {
                firebase.firestore().collection('users').doc(userId).update({
                    [`wallsFollowing.${props.id}`]: true
                })

                firebase.firestore().collection('walls').doc(props.id).update({
                    [`followers.${userId}`]: true
                })                
                
                setIsFollowing(true)
            }
        }
    }

    // Unfollow the wall by deleting its key value pair from the user object
    const unFollow = () => {
        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            const userId = firebase.auth().currentUser?.uid
            if (userId) {
                firebase.firestore().collection('users').doc(userId).update({
                    [`wall.${props.id}`]: firebase.firestore.FieldValue.delete()
                })

                firebase.firestore().collection('walls').doc(props.id).update({
                    [`followers.${userId}`]: firebase.firestore.FieldValue.delete()
                })
                
                setIsFollowing(false)
            }
        }
    }

    return (
        <span>
            <ShowModal />
            <Button variant="outline-dark"
            onClick={isFollowing ? unFollow : follow}><FontAwesomeIcon icon={faStar} /> { isFollowing ? "Remove from Favorites" : "Add To Favorites"}</Button>
        </span>
    )
}

export default FollowButton