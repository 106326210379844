import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ShowLogin from '../../components/showLogin'
import { Constants } from '../../constants'
import useCurrentUser from '../../hooks/useUser'

const Instagram = () => {

    const history = useHistory()
    const [showSignIn, setShowSignIn] = useState(false)

    const { currentUser } = useCurrentUser()

    return (
        <div>
            <ShowLogin show={showSignIn} />
            <Container style={{ maxWidth: Constants.maxWidth }}>
                <Row style={Constants.story}>
                    <Col xs="12">
                        <h1><strong>The Instagram Affiliate Program</strong></h1>
                    </Col>                    
                    <Col xs="12">
                        <div className="my-4">
                            <h3><strong>This program is designed to help you grow your Instagram account.</strong></h3>
                        </div>
                        <p>
                            A large following on Instagram could mean between $500 to $25,000 a post. Growing an account is difficult though because it's hard to get exposure.
                        </p>
                        <p>
                            <strong>The Instagram Affiliate Program is simple. </strong>
                        </p>
                        <p>
                            <strong>Any Spots on <a href="https://apps.apple.com/il/app/graffiti-social-location/id1383348851" target="_blank">Graffiti Maps</a> that you create with the hashtag #graffitimapsapp will be posted to the Graffiti Maps App Instagram, Facebook, Pinterest, and Twitter Accounts.</strong> You will receive shotouts on each of these accounts as well. This will help you to get exposed to a new audience.
                        </p>
                    </Col>

                    <Col xs="12">
                        <div className="my-4">
                            <h3><strong>Become an influencer on Graffiti Maps.</strong></h3>
                        </div>
                        <p>
                            Imagine if you were there when Instagram first started. <strong>Your following could mean between $500 to $25,000 a post</strong>. This is possible <strong>if you use an app at it's start.</strong> With the affiliate program you can easily become an influencer on Graffiti Maps with lucrative opportunities in front of you. We have a limited amount of spots so don't hesitate to sign up.
                        </p>
                        <p>
                            As an early user you become one of the original users of an application which certainly will mean a substantial following. That in turn will become revenue. So do not hesitate. 😬 
                        </p>
                    </Col>
                    
                    <Col xs="12">
                        <hr />
                        <div className="my-4">
                            <h3><strong>How to add a spot to Graffiti</strong></h3>
                        </div>
                        <p>
                            There are two ways you can add a spot/place to Graffiti
                        <ul className="mt-3">
                                <li>Download the app from the iOS App Store and add the place using the app</li>
                                <li>Use this website</li>
                            </ul>
                        </p>
                        <p>
                            <strong>Using the website is better because it will store the image more high quality.</strong> However, if you're more comfortable with a computer or you don't have an iPhone, than you can use this website on mobile or desktop.
                        </p>
                        <div style={{ padding: "25px", borderColor: "black", borderWidth: "1px", borderStyle: "dashed", borderRadius: "5px" }}>
                            <div>
                                <strong>Please Note:</strong>
                            </div>
                            <div>
                                <i>Make sure that whatever pictures you add to Graffiti Maps were taken from your phone. Pictures taken from your phone have location data stored within them. So when you add the picture to Graffiti Maps, the spot's address will be accurate.</i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="12">
                        <hr />
                        <div className="my-4">
                            <h3><strong>How to join</strong></h3>
                        </div>
                        <p>
                            Joining the affiliate program is simple. All you need to do is:
                        <ul className="mt-3">
                                <li>Create your personal Graffiti Maps account or login if you already have an account.  <Button variant="outline-dark" className="rounded-pill" onClick={() => setShowSignIn(true)}>Create an Account or Login</Button></li>
                                <li>Add 2 spots to Graffiti Maps that use the hashtag #graffitimapsapp in the description</li>
                                <li>Add your Instagram handle in each description on your Graffiti Maps posts</li>
                            </ul>
                        </p>
                        <p>
                            It's as simple as that. Once you complete those steps, you're in the affiliate program. Now, every Spot you add to Graffiti Maps I'll put on my Instagram feed with a link to your account.
                        </p>
                        <p>
                            <strong>There's only 30 spots available so act quickly before they're taken. 😬</strong>
                        </p>
                    </Col>
                    <Col xs="12">
                        {
                            currentUser &&
                            <div className="text-center alert alert-info">
                                <div className="my-3">
                                    So what are you waiting for! Let's start adding Spots now. It's very easy!
                                </div>
                                <div className="my-3">
                                    <Button variant="outline-dark" className="rounded-pill" onClick={() => history.push('/createSpot')}>Click Here to Add a Spot/Place Now</Button>
                                </div>

                            </div>
                        }

                        <p>
                            If you have any questions, contact me through Instagram - <a href="https://www.instagram.com/graffitimapsapp/" target="_blank">@graffitimapsapp</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <i>* Inappropriate content will not be accepted. If you post anything vulgar, racist, pornographic, etc, it will not be posted on my Instagram account and your Graffiti Maps account will be removed.</i>
                    </Col>
                </Row>

            </Container>
        </div>
    )

}

export default Instagram