import React from 'react'
import { CardColumns, Col, Container, Row } from 'react-bootstrap'
import TestimonialObject from '../../models/testimonialObject'
import Testimonial from './testimonial'

const TestimonialsList = (props: { testimonials: TestimonialObject[] }) => {

    return (
        <div>
            <Container style={{ maxWidth: "1000px" }}>
                <Row className="mt-2 mb-5 text-center">
                    <Col>
                        <div style={{ fontSize: "45px" }}>What people are saying about Graffiti Maps...</div>
                    </Col>
                </Row>
                <CardColumns>
                    {
                        props.testimonials.map((testimonial, idx) => <Testimonial key={idx} testimonial={testimonial} />)
                    }
                </CardColumns>
            </Container>            
        </div>
    )

}

export default TestimonialsList