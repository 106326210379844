import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Constants } from '../constants'
import User from '../models/user'

const useCurrentUser = () => {

    const [currentUser, setCurrentUser] = useState<User>()
    
    const [userLoggedIn, setUserLoggedIn] = useState<boolean>()
    
    const saveHashtagsToUser = (hashtags: Record<string, boolean>) => {
        let myHashtags = Object.keys(hashtags)

        if (!currentUser) { return }

        myHashtags.map(hashtag => {
            firebase.firestore().collection(Constants.Firebase.UsersCollection).doc(currentUser.user_id).update({
                storyTags: firebase.firestore.FieldValue.arrayUnion(hashtag)
            })
        })
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async user => {
                                    
            if (!user) { 
                setUserLoggedIn(false)
                setCurrentUser(undefined) 
                return
            }            

            setUserLoggedIn(true)

            firebase.firestore().collection('users').doc(user.uid).onSnapshot(userDoc => {
                if (userDoc.exists) {
                    setCurrentUser(userDoc.data() as User)
                }
            })
        })
    }, [])

    return { currentUser, userLoggedIn, saveHashtagsToUser }
}

export default useCurrentUser