import React, { useState, useEffect } from 'react'
import { Row, Container, Col, Image, Button } from 'react-bootstrap'
import User from '../../models/user'
import SimpleInput from '../form/input'
import SimpleTextArea from '../form/textArea'
import AddImageInput from '../addImage/addImageInput'
import { AWSService } from '../../services/aws'
import firebase from 'firebase'
import Loading from '../loading/loading'
import Utils from '../../utils/images'
import { useMediaQuery } from 'react-responsive'
import { Constants } from '../../constants'
import ErrorDialog from '../../components/errorDialog'

const EditProfile = (props: any) => {

    const [newProfileImageUrl, setNewProfileImageUrl] = useState<string | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const user = props.user as User
    const imageStyle = {
        borderRadius: '10px',
        backgroundImage: `url(${user.profile_picture_url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    const [selectedImageStyle, setSelectedImageStyle] = useState(imageStyle)
    const [name, setName] = useState(`${user.first_name ?? ""} ${user.last_name ?? ""}`.trim())
    const [username, setUsername] = useState(user.username)
    const [bio, setBio] = useState(user.bio)
    const [url, setUrl] = useState(user.siteUrl)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<string>()
    const [duplicateUsernameError, setDuplicateUsernameError] = useState<string>()

    const leftSideCaptions = {
        fontWeight: "bold" as "bold"
    }

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })

    /** When a user selects an image from the file picker updates the images background url to show the newly selected image */
    const onImagesAdded = (files: any) => {
        
        if (files.length > 0) {
            const imageUrl = URL.createObjectURL(files[0])
            setImageFile(files[0])
            setNewProfileImageUrl(imageUrl)
            setSelectedImageStyle(prevState => {
                return {
                    ...prevState,
                    backgroundImage: `url(${imageUrl})`
                }
            })
        }
    }

    useEffect(() => {
        checkUsernameIsDuplicate(username)
    }, [username])

    const checkUsernameIsDuplicate = async (myUsername: string) => {
        if (username == user.username) {
            return false
        }

        const myUser = await firebase.firestore().collection(Constants.Firebase.UsersCollection).where(Constants.Firebase.User.Username, '==', myUsername).get()

        if (myUser.empty) {
            setDuplicateUsernameError("")
            return false
        }

        setDuplicateUsernameError("Username is already taken")

        return true
    }

    const updateUserProfile = async () => {
        const userId = firebase.auth().currentUser?.uid

        if (!userId) { return }

        if (!username || username.length < 3) {
            setError("Enter a username longer than 3 characters")
            return
        }

        const usernameIsDuplicate = await checkUsernameIsDuplicate(username)

        if (usernameIsDuplicate) {
            setError(`The username ${username.toUpperCase()} is already taken`)
            return
        }

        setSaving(true)
        // If the user has add a new profile image then upload it to aws
        if (newProfileImageUrl && imageFile) {
            const awsService = new AWSService(
                'EzyhtYmhmzV1OpNYFZqCLUiFZnF3', /** User Id */
                newProfileImageUrl /** The local url of the file to upload */)

            const utils = new Utils()
            const compressedImage = await utils.compressImage(imageFile)

            if (!compressedImage) {
                return
            }

            awsService.uploadImage(userId, imageFile, `images/profile-images/${userId}`).then(imageAddress => {
                firebase.firestore().collection('users').doc(userId).update({
                    profile_picture_url: imageAddress,
                    first_name: name,
                    last_name: "",
                    username: username,
                    canonical_username: username.toLowerCase(),
                    bio: bio,
                    siteUrl: url ?? ""
                })
            }).then(() => {
                showModal()
                setSaving(false)
            }).catch(error => {
                setSaving(false)
                showModal()
            })
        } else {
            firebase.firestore().collection('users').doc(userId).update({
                first_name: name,
                last_name: "",
                username: username,
                canonical_username: username.toLowerCase(),
                bio: bio,
                siteUrl: url ?? ""
            }).then(() => {
                showModal()
                setSaving(false)
            }).catch(error => {
                setSaving(false)
                showModal()
            })
        }
    }

    /** Call the show modal function of the parent component */
    const showModal = () => {
        if (props.showModal) {
            props.showModal(false)
        }
    }

    return (
        <div>
            {
                saving && <Loading />
            }
            <ErrorDialog error={error} setError={setError} />
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs="9" md="auto">
                        <Image
                            height="150px"
                            width="150px"
                            style={selectedImageStyle} />
                    </Col>

                    {
                        isMobile && <Col xs="2">
                            <Button variant="dark" onClick={props.close}>
                                <span className="font-weight-bold">X</span>
                            </Button>
                        </Col>
                    }
                </Row>
                <Row className="justify-content-md-center mt-3">
                    <Col md="auto">
                        <div>
                            <AddImageInput variant='light' caption="Change Profile Picture" onChange={onImagesAdded} />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Row className="mt-3">
                            <Col xs="12" md="3" className="p-0 align-items-center" >
                                <div className="ml-auto p-2" style={leftSideCaptions}>Full Name</div>
                            </Col>
                            <Col xs="12" md="9" >
                                <SimpleInput
                                    onChange={setName}
                                    placeholder="Enter your name"
                                    value={name}
                                    customClassName="d-flex align-items-center" />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12" md="3" className="p-0 align-items-center" >
                                <div className="ml-auto p-2" style={leftSideCaptions}>Username</div>
                            </Col>

                            <Col xs="12" md="9" >
                                <SimpleInput
                                    onChange={setUsername}
                                    placeholder="Enter your display name / username"
                                    value={username}
                                    customClassName="d-flex align-items-center" />
                                <div className="mt-1" style={{ color: 'red' }}>
                                    {duplicateUsernameError}
                                </div>
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col xs="12" md="3" className="p-0 align-items-center" >
                                <div className="ml-auto p-2" style={leftSideCaptions}>Bio</div>
                            </Col>
                            <Col xs="12" md="9" >
                                <SimpleTextArea
                                    onChange={setBio}
                                    placeholder="Enter some information about yourself"
                                    value={bio}
                                    customClassName="d-flex align-items-center" />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12" md="3" className="p-0 align-items-center" >
                                <div className="ml-auto p-2" style={leftSideCaptions}>Your Site Url</div>
                            </Col>
                            <Col xs="12" md="9" className="pl-0" >
                                <SimpleInput
                                    onChange={setUrl}
                                    placeholder="(optional) Your current site or blog"
                                    value={url}
                                    customClassName="d-flex align-items-center" />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12" md={{ offset: 3 }} >
                                <Button variant="dark" className="rounded-pill ml-4" style={{ fontWeight: "bold" }} onClick={updateUserProfile}>Save</Button>
                                {
                                    props.showCancelButton &&
                                    <Button variant="danger" className="rounded-pill ml-4" style={{ fontWeight: "bold" }} onClick={() => showModal()}>Cancel</Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default EditProfile