import React, { useState, useEffect } from 'react'
import Loading from '../loading/loading'
import { Modal, ModalBody, Col, Button, Container, Row, Image } from 'react-bootstrap'
import Utilities from '../../utilities'
import SwitchControl from '../switch-control/switch-control'
import { navigate } from '@storybook/addon-links/dist/preview'
import SignIn from '../../pages/signIn/signIn'
import SpotObject from './spotObject'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import AddToWall from '../wall/addToWall'
import { useHistory, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import FacebookShareButton from 'react-share/lib/FacebookShareButton'
import FacebookIcon from 'react-share/lib/FacebookIcon'
import Share from '../share'
import ProfileSection from '../profileSection'
import useSpot from '../../hooks/useSpot'

const ViewSpotComponent = (props: { spotId: string }) => {

    const { spot } = useSpot(props.spotId)

    const [showSaveSpotModal, setShowSaveSpotModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isCurrentUsersSpot, setIsCurrentUsersSpot] = useState(false)
    const [user, setUser] = useState({} as any)

    const history = useHistory()

    useEffect(() => {
        if (!spot) { return }
        firebase.firestore().collection('users').doc(spot.data.user_id).get()
            .then(user => {
                setIsCurrentUsersSpot(firebase.auth().currentUser?.uid == spot?.data.user_id)
                const userData = user.data()
                setUser(userData)
            })
    }, [spot])

    const ShowModal = () => {
        return (
            <div>
                {
                    showLoginModal &&
                    <Modal size="sm" show={true} animation={true} centered>
                        <ModalBody>
                            <SignIn close={() => setShowLoginModal(false)} />
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }

    const deleteSpot = () => {
        if (!spot) { return }
        firebase.firestore().collection('tags').doc(spot.id).delete().then(() => {
            console.log('Spot Deleted')
            history.push('/home')
        }).catch(error => {
            console.error("Error deleting spot: ", error)
        })
    }

    const deleteSpotPressed = () => {
        setShowDeleteModal(true)
    }

    const save = () => {
        if (!firebase.auth().currentUser) {
            setShowLoginModal(true)
        } else {
            setShowSaveSpotModal(true)
        }
    }

    const navigate = () => {
        if (!spot) { return }
        window.open(`https://google.com/maps/dir/?api=1&origin=&destination=${spot.data.location.latitude},${spot.data.location.longitude}`, '_blank')
    }

    return (

        <div>
            <ShowModal />
            {
                showSaveSpotModal && <AddToWall spot={spot} exitButtonPressed={() => setShowSaveSpotModal(false)} />
            }
            {
                (!spot || !spot.id) && <Loading />
            }
            {
                spot && spot.id &&
                <div>
                    <Modal size="sm" show={showDeleteModal} animation={true} centered>
                        <ModalBody>
                            <Col>
                                <span className="font-weight-bold">Are you sure you want to delete this Spot '{spot.data.name}'?</span>
                            </Col>
                            <Col className="mt-3">
                                <Button className="rounded-pill" style={{ backgroundColor: "red", border: "none" }} onClick={deleteSpot}>Delete</Button>
                                <Button className="ml-3 rounded-pill" variant="dark" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            </Col>
                        </ModalBody>
                    </Modal>
                    <Container>
                        <Row className="justify-content-md-center mb-5">
                            <Col xs="12" md="6">
                                <Container>
                                    <Row>
                                        <Col xs="12" className="p-0" >
                                            <Image className="graffiti-image global-radius" src={spot.data.picture_url} width="300" height="500" />
                                        </Col>
                                    </Row>
                                </Container>

                            </Col>
                            <Col xs="12" md="6" className="mt-3">
                                <Container>
                                    <Row className="justify-content-md-center">
                                        <Col xs="12">
                                            <div>
                                                <strong>Share this spot on your social media:</strong>
                                            </div>
                                            <Share name={spot.data.name} url={Utilities.getSpotUrl(spot)} imageUrl={spot.data.picture_url} />
                                        </Col>
                                        <Col xs="12">
                                            <p className="font-weight-bold view-spot-title">{spot.data.name}</p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <FontAwesomeIcon icon={faMapMarkerAlt} /> <span className="font-weight-bold" style={{ textTransform: "capitalize" }}>{spot.data.address}</span>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-3">
                                            <span className="font-weight-bold">For people interested in: </span><span>{Utilities.convertJsonToList(spot.data.hashtags)}</span>
                                        </Col>
                                    </Row>
                                    {/* The profile image for the user who posted this Spot */}
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-3">
                                            <ProfileSection user={user} />
                                        </Col>
                                    </Row>

                                    {/* The address of the Spot */}
                                    <Row className="justify-content-md-center">
                                        <Col className="mt-1">
                                            <p>{spot.data.description != null ? spot.data.description.split('\n').map((item: string) => {
                                                return (
                                                    <p key={uuidv4()}>
                                                        {item}
                                                    </p>
                                                )
                                            }) : ""}</p>
                                        </Col>
                                    </Row>

                                    {/* The Save and Navigate buttons */}
                                    <Row className="justify-content-md-left mt-2">
                                        <Col xs="auto">
                                            <SwitchControl
                                                buttonTitles={isCurrentUsersSpot ? ['Save', 'Navigate'] : ['Save', 'Navigate']}
                                                buttonFunctions={isCurrentUsersSpot ? [save, navigate] : [save, navigate]}
                                                variants={isCurrentUsersSpot ? ['dark', 'dark'] : ['dark', 'dark']}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs="12" md="2">

                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    )

}

export default ViewSpotComponent