import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Modal, ModalBody, Row } from 'react-bootstrap'
import { Constants } from '../constants'
import useCurrentUser from '../hooks/useUser'
import AddToWall from './wall/addToWall'
import Utilities from '../utilities'
import ProfileSection from './profileSection'
import SpotData from './spot/spotData'
import SpotObject from './spot/spotObject'
import useFirebaseUser from '../hooks/useFirebaseUser'
import { useHistory } from 'react-router-dom'
import useStories from '../hooks/useStories'
import StoriesGrid from './storiesGrid'
import ShowLogin from './showLogin'

const SpotCard = (props: {
    mockSpot?: {
        spot: SpotObject,
        imageUrl: string
    },
    id: string,
    spotObject?: SpotObject, action?: {
        text: string,
        callback: any
    }[],
    style?: any,
    highlight?: boolean
}) => {

    const [spot, setSpot] = useState<SpotObject>()

    const { firebaseUserDoc } = useFirebaseUser(spot?.data?.user_id ?? "")

    const { stories } = useStories(firebase.firestore().collection(Constants.Firebase.Stories).where(`allSpots.${props.id}`, '==', true))

    const history = useHistory()

    const [showStories, setShowStories] = useState(false)
    
    const [showLogin, setShowLogin] = useState(false)

    const [selectedSpotToSave, setSelectedSpotToSave] = useState<SpotObject>()

    const { currentUser, userLoggedIn } = useCurrentUser()

    const gotoSpot = () => {
        if (!spot) { return }
        const win = window.open(`/viewSpot/${spot.id}/${spot.data.name.split(' ').join('_')}`)
        win?.focus()
    }

    const navigate = (spot: SpotObject) => {
        window.open(`https://google.com/maps/dir/?api=1&origin=&destination=${spot.data.location.latitude},${spot.data.location.longitude}`, '_blank')
    }

    useEffect(() => {
        if (props.mockSpot) {
            setSpot(props.mockSpot.spot)
            return
        }
        if (props.spotObject) {
            setSpot(props.spotObject)
            return
        }

        firebase.firestore().collection(Constants.Firebase.Tags).doc(props.id).get().then((result => {
            if (!result.exists) { return }

            const spotObject: SpotObject = {
                data: result.data() as SpotData,
                id: result.id
            }

            const spot = spotObject
            setSpot(spot)
        }))
    }, [])

    return (
        <div>
            <ShowLogin show={showLogin} />
            {
                selectedSpotToSave && 
                <AddToWall spot={selectedSpotToSave} exitButtonPressed={() => setSelectedSpotToSave(undefined)} />
            }
            <Modal size="lg" show={showStories}>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col xs="12">
                                <Button variant="outline-danger" onClick={() => setShowStories(false)} className="mb-3" >Close</Button>
                            </Col>
                            <Col xs="12" className="my-3" style={{ fontSize: "25px" }}>
                                <strong>Stories with this spot in it...</strong>
                            </Col>
                            <Col xs="12">
                                <StoriesGrid action={() => setShowStories(false)} stories={stories} />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>

            {
                spot &&
                <Card
                    text={props.highlight ? "white" : "dark"}
                    key={spot.data.last_modified_time ?? spot.id}
                    style={props.style ?? {
                        backgroundColor: props.highlight ? "#479fb5" : "white"
                    }} className="mt-2">
                    {
                        firebaseUserDoc &&
                        <Card.Header style={{ backgroundColor: "white" }}><ProfileSection user={firebaseUserDoc}  ></ProfileSection></Card.Header>
                    }
                    <Card.Img variant="top" src={props.mockSpot?.imageUrl ?? spot.data.picture_url} onClick={gotoSpot} />
                    <Card.Body>

                        <div className="text-clamp-5" onClick={gotoSpot}>
                            <Card.Title><strong>{spot.data.name}</strong></Card.Title>
                            <Card.Text>For people interested in: <strong> {Utilities.convertJsonToList(spot.data.hashtags)}</strong></Card.Text>
                        </div>
                        <div className="text-clamp-5 mt-3" onClick={gotoSpot}>
                            <Card.Text>{spot.data.description}</Card.Text>
                        </div>
                        <div className="mt-4">
                            {
                                props.action &&
                                props.action.map(action => {
                                    return (
                                        <Button
                                            key={action.text}
                                            className="rounded-pill mr-2 my-2"
                                            variant={props.highlight ? "outline-light" : "outline-dark"}
                                            onClick={() => action.callback(spot.id)}>{action.text}</Button>
                                    )
                                })
                            }
                            <Button
                                className="rounded-pill mr-2 my-2"
                                variant="outline-dark"
                                onClick={() => { setSelectedSpotToSave(spot) }}>
                                Save Spot
                            </Button>
                            <Button
                                className="rounded-pill mr-2 my-2"
                                variant="outline-dark"
                                onClick={() => navigate(spot)} >
                                Navigate
                            </Button>
                            <Button
                                as="a"
                                target="_blank"
                                className="rounded-pill mr-2 my-2"
                                variant={props.highlight ? "outline-light" : "outline-dark"}
                                href={`/viewspot/${spot.id}/${spot.data.name}`}>
                                View Details
                            </Button>
                            {
                                stories && stories.length > 0 &&
                                <Button
                                    className="rounded-pill mr-2 my-2"
                                    variant={props.highlight ? "outline-light" : "outline-dark"}
                                    onClick={() => setShowStories(true)}>
                                    View Stories
                            </Button>
                            }
                        </div>
                    </Card.Body>
                </Card>
            }
        </div>
    )
}

export default SpotCard