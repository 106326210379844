import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import SignIn from '../pages/signIn/signIn'

const ShowLogin = (props: { show: boolean, hideClose?: boolean, close?: any }) => {

    const [showLoginModal, setShowLoginModal] = useState(false)

    useEffect(() => {
        setShowLoginModal(props.show)
    }, [props.show])

    const hideModal = () => {
        setShowLoginModal(false)
    }

    return (
        <div>
            {
                showLoginModal &&
                <Modal size="sm" show={true} animation={true} backdrop="static" onHide={hideModal} centered>
                    <ModalBody>
                        <SignIn hideClose={props.hideClose} close={ () =>  {
                            if (props.close) {
                                props.close()
                            }

                            setShowLoginModal(false)
                        }  } />
                    </ModalBody>
                </Modal>
            }
        </div>
    )
}

export default ShowLogin