import AWS from 'aws-sdk'
import env from 'dotenv'

class AWSService {

    /** The id of the user who is uploading this image */
    private userId: string

    /** The name of the file to save.  Make sure that this is unique */
    private fileUrl: string

    /**
     * 
     * @param userId The id of the user who is uploading this image
     * @param fileUrl The local url of the file to upload to s3
     */
    constructor(userId: string, fileUrl: string) {
        this.userId = userId
        this.fileUrl = fileUrl
        env.config()
        AWS.config.update({
            accessKeyId: 'AKIAS5AOYCYEPNT3GUUJ',
            secretAccessKey: '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'
        })

        console.log(process.env)
    }

    /**
     * @param fileName What do you want this files name to be in the S3 bucket. Make sure that the fileName is unique. I would recommend this just being a UUID value
     * Upload the image to AWS
     */
    uploadImage = async (fileName: string, image: File | Blob, imageUrl:string) => {
            
        // The params containing the information to upload a file to a bucket
        const uploadParams = {
            Bucket: 'graffiti-us-production',
            Key: imageUrl,
            Body: image
        }

        const s3 = new AWS.S3

        return new Promise((resolve, reject) => {
            s3.upload(uploadParams, (error: any, data: any) => {
                if (error) {
                    reject(error)
                }
                if (data) {
                    resolve(data.Location)
                }
            })
        })
    }
}

export { AWSService }