import React, { useEffect } from 'react'
// Import FirebaseAuth and firebase.
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import '../signIn/signIn.css'
import logo from '../../assets/logo.png'
import User from '../../models/user';

const SignIn = (props: {
    close?: any
    hideClose?: boolean
}) => {

    // Configure FirebaseUI. 
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        // signInSuccessUrl: `/${redirectUrl}` ?? '/signedIn',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false
        }
    };

    /** If the user does not have a document that exists in firestore than add it now  */
    const createUser = async (user: firebase.User) => {

        const userDoc = await firebase.firestore().collection("users").doc(user.uid).get()
        if (userDoc.exists) { return }

        const newUserDoc: User = {
            first_name: "",
            last_name: "",
            followers: {},
            following: {},
            isActive: true,
            isBlocked: false,
            profile_picture_url: "",
            username: "",
            user_id: user.uid,
            bio: "A new Graffiti Maps user",
            location: "",
            piece_count: 0,
            email: user.email ?? ""
        }

        firebase.firestore().collection("users").doc(user.uid).set(newUserDoc).then(result => {
            console.log("New user document created and added to firestore")
        }).catch(error => {
            console.error("Error adding new user document to firestore " + error)
        })
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                if (!props.close) { return }

                props.close()
                createUser(user)
            }
        })
    }, [])

    return (
        <div>
            <div>
                {
                    props.hideClose != true &&
                    <Button variant="dark" onClick={props.close} className="font-weight-bold">X</Button>
                }
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Image height="100px" src={logo} />
                    </Col>
                    <Col xs="auto" className="mt-3">
                        <h1 style={{ fontWeight: "normal", textAlign: "center" }}>Welcome to Graffiti It</h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <p style={{ textAlign: "center" }}>Please sign-in or create an account to perform this task:</p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs="12" md="auto" style={{ textAlign: "center" }}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                    </Col>
                </Row>
            </Container>
        </div>

    );

}

export default SignIn