import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import firebase from 'firebase'
import SpotGrid from '../components/spot/spotGrid'
import { Container, Row, Col } from 'react-bootstrap'

const SearchPage = () => {

    const { hashtag } = useParams()
    const [query, setQuery] = useState<any>(null)

    useEffect(() => {
        if (query) {
            window.location.reload()
        }
        setQuery(firebase.firestore().collection('tags').where(`hashtags.${hashtag}`, '==', true))
        
    }, [hashtag])
    
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1>
                            {`#${hashtag}`}
                        </h1>
                    </Col>
                </Row>
            </Container>
            {
                query && 
                <SpotGrid query={query} />
            }
        </div>
    )
}

export default SearchPage