import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import SwitchControl from '../switch-control/switch-control'
import AddImageInput from '../addImage/addImageInput'
import { PlaceDetails } from '../autocomplete/placeDetails'

const AddImage = (props: any) => {

    const imageRef = useRef<HTMLDivElement>(null)
    const [selectedImageStyle, setSelectedImageStyle] = useState({ display: 'none' })
    const [imageRotation, setImageRotation] = useState(0)

    const addImageStyleInitialState = {
        backgroundColor: '#efefef',
        borderRadius: '10px'
    }

    const [addImageStyle, setAddImageStyle] = useState(addImageStyleInitialState)

    const dashedBorder = {
        position: "absolute" as "absolute",
        width: "90%",
        height: "280px",
        borderStyle: "dotted",
        top: "10px",
        borderColor: 'grey'
    }

    const fileListToArray = (list: any[]) => {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list[i]);
        }
        return array;
    }

    const onImagesAdded = async (files: any) => {                
        setImageRotation(0)
        if (props.onImagesAdded) {
            const array = fileListToArray(files)
            props.onImagesAdded(array)
        }

        if (files.length > 0 && imageRef.current) {
            const imageUrl = URL.createObjectURL(files[0])
            
            setAddImageStyle(prevState => {
                return ({
                    ...prevState,
                    backgroundColor: 'white'
                })
            })

            setSelectedImageStyle(() => {
                return {
                    position: "absolute" as "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: '10px',
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    display: 'block',
                    zIndex: 1
                }
            })
        }
    }

    /** When the user presses the delete button to remove the image that they just uploaded */
    const deleteButtonPressed = () => {

        setAddImageStyle(addImageStyleInitialState)
        props.resetLocation()

        setSelectedImageStyle(prevState => {
            return {
                ...prevState,
                display: 'none'
            }
        })
    }

    const updateImageRotate = () => {
        let imageStyle = selectedImageStyle as any
        let newImageRotation = imageRotation + 90
        setImageRotation(newImageRotation)
        setSelectedImageStyle((previousImageStyle) => {
            return {
                ...previousImageStyle,
                transform: `rotate(${newImageRotation}deg)`
            }
        })
    }

    return (
        <div id="addImageWrapper" ref={imageRef}>
            <Col xs="auto" style={props.style ?? addImageStyle}>
                <Container>
                    <Row className="justify-content-center">
                        <div style={selectedImageStyle}>

                        </div>
                        {
                            selectedImageStyle.display == 'none' &&
                            <div style={dashedBorder}>

                            </div>
                        }
                        <Col xs="12" style={{ height: '100px' }}>

                        </Col>

                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto" style={{ height: '100px' }}>
                            <div>
                                <AddImageInput style={{ width: "150px" }} onChange={onImagesAdded} spotLocationRetrieved={ props.spotLocationRetrieved } />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto" style={{ height: '100px' }}>
                            <div style={{ width: '200px', textAlign: 'center' }}>
                                Click or drag and drop to upload your images
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col xs="12">
                {
                    selectedImageStyle.display != 'none' &&
                    <SwitchControl
                        buttonTitles={['Delete', 'Rotate Image']}
                        variants={['dark', 'dark']}
                        buttonFunctions={[deleteButtonPressed, updateImageRotate]} />
                }

            </Col>
        </div>

    )
}

export default AddImage