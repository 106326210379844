import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SwitchControl from '../../components/switch-control/switch-control'
import SpotGrid from '../../components/spot/spotGrid'
import Redirect, { Link, withRouter } from 'react-router-dom'

const ChangeViewSwitch = (props: any) => {

    const [redirect, setRedirect] = useState("")
    const variants = props.variants

    function wallsButtonPressed() {
        window.scrollTo(0, 0)
        return (
            props.history.push('/walls')
        )
    }

    function spotsButtonPressed() {
        return (
            props.history.push('/')
        )
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <SwitchControl
                            buttonTitles={['Spots', 'View Walls']}
                            buttonFunctions={[spotsButtonPressed, wallsButtonPressed]}
                            variants={variants ?? ['dark', 'light']}>
                        </SwitchControl>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default withRouter(ChangeViewSwitch)