import firebase from 'firebase'
import SpotObject from './components/spot/spotObject'
import WallObject from './components/wall/wallObject'
import { Constants } from './constants'

class Utilities {

    static logData (message: string, isError: boolean) {
        firebase.firestore().collection(Constants.Firebase.Log).add({
            message: message,
            isError: isError
        })
    }

    static removeTags (str?: string) {
        return str?.replace( /(<([^>]+)>)/ig, '') ?? "";
    }

    static getSpotUrl (spot: SpotObject) {
        return `https://graffitiapp.co/${Constants.UrlSections.ViewSpot}/${spot.id}/${ spot.data.name.split(' ').join('_') }`
    }

    static getWallUrl (id: string, name:string) {
        return `https://graffitiapp.co/${ Constants.UrlSections.Wall }/${ id }/${name.split(' ').join('_')}`
    }

    /**
     * Converts a json object of type { key: boolean }, into an array of the keys
     * @param json An object of type { key: boolean }
     */
    static convertJsonToList (json:any) {
        if (!json) {
            return
        }
        let arrayOfKeys = Object.keys(json)
        if (arrayOfKeys) {
            return arrayOfKeys.join(', ')
        }

        return ""
    }

    static getHashtagObjectFromString = (text:string) => {
        let hashtags = text.split(/(?=#)/g)
        hashtags = hashtags.filter(object => {
            return object.startsWith('#')
        })

        let hashtagObject = {} as any

        hashtags.map(hashtag => {

            const hashtagName = hashtag.split(' ')[0].replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '')            
            hashtagObject[hashtagName] = true
        })

        return hashtagObject
    }

    /**
     * Save a group of hashtags stored in a hashtag object.  The keys should be the hashtags, the value is irrelevant, but to keep with
     * the standard, the value should be set to true for all keys
     * @param hashtagObject - The key value object containing the hashtags as keys
     */
    static saveHashtagsToFirebase (hashtagObject:any) {
        let hashtags = Object.keys(hashtagObject)
        hashtags.forEach(hashtag => {
            firebase.firestore().collection('hashtags').add({
                name: hashtag
            })
        });
    }
}

export default Utilities