import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
const ShareStoryButton = () => {

    const history = useHistory()

    const shareStoryPressed = () => {
        history.push(`/c/${uuidv4().substring(0, 8)}`)
    }

    return (

        <div>
            <Button className="rounded-pill" variant="outline-dark" onClick={shareStoryPressed} >+ Start Writing NOW</Button>
        </div>

    )

}

export default ShareStoryButton