import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './switch-control.css'

type OnClickFunction = () => any

/**
 * Displays a list of pill-shaped buttons in a horizontal row
 * @param props
 */
const SwitchControl = (props: any) => {

    function ButtonList(props: any) {

        const buttonTitles: string[] = props.data.buttonTitles;
        const buttonFunctions: OnClickFunction[] = props.data.buttonFunctions;

        const getButtons = (): JSX.Element[] => {
            return buttonTitles.map((title, index) => {
                let clickFunction = buttonFunctions[index] as Function
                let variants = props.data.variants
                let variant

                if (variants) {
                    variant = variants[index] ?? 'light'
                } else {
                    variant = 'light'
                }

                return (
                    <Col md="auto" xs="6" className="pl-0">
                        <Button
                            key={ title }
                            variant={variant}
                            className="mt-2 rounded-pill switch-control-button-title"
                            onClick={() => onButtonClicked(index, clickFunction)}>{title}</Button>
                    </Col>
                )
            })
        }

        const [buttons, setButtons] = useState<JSX.Element[]>(getButtons())

        const onButtonClicked = (index: number, clickFunction: Function) => {
            let myVariants: ('dark' | 'light')[] = []
            var counter = 0

            buttonTitles.forEach(_ => {
                if (counter === index) {
                    myVariants.push('dark')
                } else {
                    myVariants.push('light')
                }

                counter++
            });

            clickFunction(myVariants)
        }

        return (
            <div>
                <Container>
                    <Row className={props.data.customClassName ?? "justify-content-md-center"}>
                        {buttons}
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <div>
            <ButtonList data={props}></ButtonList>
        </div>
    )
}

export default SwitchControl;