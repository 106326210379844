import React, { useRef } from 'react'
import { Button, Col, Container, Modal, ModalBody, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { Constants } from '../constants'
import { TutorialVideo } from '../objects/tutorialVideo'

const VideoModal = (props: { video: TutorialVideo, close: any }) => {

    /** A reference to the ReactPlayer so that we can control it within this page */
    const ref = useRef<ReactPlayer>(null)

    return (

        <Container>
            <Row>
                <Col>
                    <Button variant="light" onClick={props.close}><strong>X</strong></Button>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <ReactPlayer                        
                        style={{ borderColor: "black", borderWidth: "3px" }}
                        url={props.video.url}
                        ref={ref}
                        playsinline={true}
                        controls={true}
                        config={
                            {
                                file: { forceHLS: true }
                            }
                        } />
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs="12">
                    <h2>{props.video.caption}</h2>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <div style={{ backgroundColor: Constants.Colors.Tan, padding: "30px", borderRadius: "15px"}}>
                        <div>
                            <h4>Description</h4>
                        </div>
                        <div>
                            {props.video.description}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default VideoModal