import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import User from '../models/user'
import Profile from '../pages/profile/profile'

const ProfileSection = (props: {
    user: User
}) => {

    const history = useHistory()

    const profileClicked = () => {
        history.push(`/p/${props.user.username.split(' ').join('_')}`)
    }

    return (
        <Row className="justify-content-md-center" >
            <Col >
                <div>
                    <Image style={{ cursor: "pointer" }} onClick={profileClicked} className="profileImage" src={props.user.profile_picture_url} width="30" height="30" roundedCircle />
                    <span onClick={profileClicked} style={{ cursor: "pointer", fontSize: "14px", color: "black" }} className="ml-2 font-weight-bold">{props.user.username}</span>
                </div>
            </Col>
        </Row>
    )
}

export default ProfileSection