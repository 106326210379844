import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { Constants } from '../constants'

const ErrorDialog = (props: {
    error?: string,
    setError: any
}) => {

    const [error, setError] = useState()

    useEffect(() => {
        setError(props.error)        
    }, [props.error])

    return (
        <Modal  size="sm" show={error !== undefined} animation={true} centered>
            <ModalBody style={{ backgroundColor: Constants.Colors.Red }}>
                <div className="m-3">
                    <div style={{ fontSize: "20px", color: "white" }}>
                        <strong>{error}</strong>
                    </div>
                    <Button className="my-3 rounded-pill" onClick={ () => {
                         setError(undefined) 
                         props.setError(undefined)                         
                    }} variant="outline-light">Okay</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ErrorDialog