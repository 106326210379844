import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProfileSection from '../../components/profileSection'
import { Constants } from '../../constants'
import useFirebaseUser from '../../hooks/useFirebaseUser'
import useSpot from '../../hooks/useSpot'
import useCurrentUser from '../../hooks/useUser'
import StoryObject from '../../models/storyObject'
import StorySection from '../../models/storySectionObject'
import Utilities from '../../utilities'
import './storyCard.css'

const StoryCard = (props: {
    mockStory?: {
        story: StoryObject
        imageUrl: string,
    },
    id: string, action: [{
        text: string,
        callback: any
    }]
}) => {

    const history = useHistory()

    const [story, setStory] = useState<StoryObject>()

    const [firstSection, setFirstSection] = useState<StorySection>()

    const { spot } = useSpot(story?.mainSpot)

    const { firebaseUserDoc } = useFirebaseUser(story?.user_id ?? "")

    const { currentUser } = useCurrentUser()

    useEffect(() => {
        if (props.mockStory) {
            const mockStory = props.mockStory
            setStory(mockStory.story)

            if (mockStory.story.storySections.length > 0) {
                const firstSection = mockStory.story.storySections[0]
                setFirstSection(firstSection)
            }

            return
        }

        firebase.firestore().collection(Constants.Firebase.Stories).doc(props.id).get().then(result => {
            if (!result.exists) { return }

            const myStory = result.data() as StoryObject
            const section = myStory.storySections[0]

            setFirstSection(section)
            setStory(myStory)
        })
    }, [])

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div>
            <hr />
            {
                story && firstSection &&
                <Card className="text-left" style={{ border: "none" }}>
                    {
                        firebaseUserDoc &&
                        <a href={`/s/${story.id}`}>
                            <Card.Header style={{ backgroundColor: "white", padding: 0, color: "black" }}>
                                <div className="mt-3">
                                    <Card.Title>
                                        <h1><strong>{`${story.isPublished ? "" : "NOT FINISHED - "}${Utilities.removeTags(story.title?.split(' ').map(capitalize).join(' '))}`}</strong></h1>
                                    </Card.Title>
                                </div>
                                <div className="my-3">
                                    Story written by <strong>{firebaseUserDoc.username}</strong>
                                </div>
                            </Card.Header>
                        </a>
                    }
                    <Card.Img variant="top" src={props.mockStory?.imageUrl ?? spot?.data.picture_url ?? ""} />
                    <Card.Body style={{ padding: 0 }}>
                        {
                            firebaseUserDoc &&
                            <div className="my-3">
                                {<ProfileSection user={firebaseUserDoc}></ProfileSection>}
                            </div>
                        }
                        <div style={{ fontSize: "18px", lineHeight: "32px" }} className="text-clamp-10">
                            <Card.Text> <span dangerouslySetInnerHTML={{ __html: Utilities.removeTags(firstSection.content) }}></span></Card.Text>
                        </div>
                        <div className="mt-4">
                            {
                                props.action.map(action => {
                                    return (
                                        <Button
                                            key={action.text}
                                            className="rounded-pill mr-2"
                                            variant="outline-dark"
                                            onClick={() => action.callback(story.id)}>{action.text}</Button>
                                    )
                                })
                            }
                        </div>
                    </Card.Body>
                </Card>
            }
        </div>
    )

}

export default StoryCard