import React, { useState } from 'react'
import { Col, Form, FormControl } from 'react-bootstrap'

const SimpleInput = (props:any) => {

    const [value, setStateValue] = useState(props.value)

    const simpleInputStyle = ():{} => {
        if (props.header === true) {
            return {
                fontSize: '18px',
                fontWeight: 'bold'
            }
        }
        return { 
            fontSize: '16px',
        }
    }

    const setValue = (value:any) => {
        setStateValue(value.target.value)
        props.onChange(value.target.value)
    }

    const lineStyle = {
        marginLeft: '15px'
    }    

    return (        
        <Col style={{ 
            paddingLeft: "0px",
            paddingRight: "0px"
        }} className={props.customClassName}>
            <FormControl
                value={value}
                onChange={setValue}
                disabled={props.disabled}
                size="lg" 
                type="text" 
                placeholder={props.placeholder} 
                style={props.style ?? simpleInputStyle()}
                onFocus={props.onFocus}
                onBlur={props.onBlur} />
        </Col>
    )

}

export default SimpleInput