import React, { useEffect, useState } from 'react'
import search from '../components/search/search'

const useSearchTimer = (searchText: string, search: any) => {

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {

        if (searchText.trim() == "") {
            if (timer) {
                clearTimeout(timer)
            }

            search(searchText.toLocaleLowerCase())
            return
        }

        // First time timer will be nil and we want to just return
        if (!timer) {
            setTimer(setTimeout(() => search(searchText.toLowerCase()), 500))
            return
        }

        // Clear the timer so that we don't execute a previous search
        clearTimeout(timer)
        setTimer(setTimeout(() => search(searchText.toLowerCase()), 500))

    }, [searchText])

}

export default useSearchTimer