import firebase from 'firebase';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Image, Modal, ModalBody, CardColumns, CardDeck } from 'react-bootstrap';
import Editor from 'react-medium-editor'
import { useHistory, useParams } from 'react-router-dom';
import CreateSpot from "../../components/create-spot/createSpot";
import ShowLogin from '../../components/showLogin';
import { Constants } from '../../constants';
import useCurrentUser from '../../hooks/useUser';
import StoryObject from '../../models/storyObject';
import '../../index.css'
import Utilities from '../../utilities';
import StorySection from '../../models/storySectionObject';
import SpotCardGrid from '../../components/spotCardGrid';
import SpotCard from '../../components/spotCard';
import useFirebaseUser from '../../hooks/useFirebaseUser';
import useSpots from '../../hooks/useSpots';
import useCurrentUsersSpots from '../../hooks/useSpots';
import { v4 as uuidv4 } from 'uuid'
import SimpleInput from '../../components/form/input';

// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');


const CreateStory = () => {

    /** The timer responsible for handling when the story should be automatically saved */
    var [autoSaveTimer, setAutoSaveTimer] = useState<NodeJS.Timeout>()

    /** The amount of time to wait before saving the updated story */
    const autoSaveTimeInterval = 1000

    /** Whether or not to automatically save the latest update for the story */
    var shouldAutoSave = false

    var [content, setContent] = useState("")

    /** The id of the draft */
    const { draftId } = useParams()

    /** The current user if she's logged in */
    const { currentUser, userLoggedIn } = useCurrentUser()

    /** Whether the page is currently in the save draft state */
    const [isSaving, setIsSaving] = useState(false)

    /** Is the story finished loading? */
    const [loaded, setLoaded] = useState(false)

    /** Currently in the process of doing something like publishing a story for example? */
    const [processing, setProcessing] = useState(false)

    /** Every time the story is updated, this will be updated  */
    const [storyUpdated, setStoryUpdated] = useState(0)

    const [modalState, setModalState] = useState("")

    const { spots } = useCurrentUsersSpots()

    const [sectionToDeleteIndex, setSectionToDeleteIndex] = useState<number>()

    const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0)

    const [canonicalUrl, setCanonicalUrl] = useState<string>()

    enum ModalStates {
        SignIn = "SignIn",
        CreateSpot = "CreateSpot",
        AddSpot = "AddSpot",
        Delete = "Delete",
        NoSpot = "NoSpot",
    }

    /** The of the current section that the user is adding a spot to */
    const [addToSection, setAddToSection] = useState<{
        section: StorySection,
        sectionId: string
    }>()

    const history = useHistory()

    /** We give the story this initial state only so that we can display the complete UI without any issues. After the user is verified to be logged in, we update the story object */
    const [story, setStory] = useState<StoryObject>({
        id: draftId ?? "",
        user_id: currentUser?.user_id ?? "",
        isPublished: false,
        mainSpot: "",
        allSpots: {},
        storySections: [{
            spots: [],
            content: "",
            id: ""
        }]
    })

    const { firebaseUserDoc } = useFirebaseUser(story.user_id ?? "")

    const [editorOptions, setEditorOptions] = useState<any>()

    useEffect(() => {

        if (userLoggedIn == undefined) { return }

        if (userLoggedIn == false) {
            setModalState(ModalStates.SignIn)
            return
        }

        if (story) { return }

        const myNewStory = newStory()
        if (!myNewStory) { return }

        setStory(myNewStory)

    }, [userLoggedIn])

    useEffect(() => {

        setEditorOptions(
            {
                paste: {
                    forcePlainText: false
                },
                toolbar:
                {
                    buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote']
                }
            }
        )
    }, [])


    useEffect(() => {
        shouldAutoSave = true
        if (!autoSaveTimer) { return }
        clearInterval(autoSaveTimer)

        setAutoSaveTimer(setInterval(saveDraft, autoSaveTimeInterval))
    }, [storyUpdated])

    useEffect(() => {
        initialize()
    }, [])

    const initialize = () => {
        setAutoSaveTimer(setInterval(saveDraft, autoSaveTimeInterval))

        firebase.firestore().collection(Constants.Firebase.Stories).doc(draftId).get().then(result => {

            if (!result.exists) {
                const myNewStory = newStory()

                if (myNewStory) {
                    setStory(myNewStory)
                }
                setLoaded(true)
                return
            }

            const story = result.data() as StoryObject

            setStory(story)

            setLoaded(true)
        })
    }

    useEffect(() => {
        initialize()
    }, [draftId])

    const handleTitleChange = (text: any, medium: any) => {
        const myStory = story ?? newStory()

        if (!myStory) { return }

        myStory.title = text
        updateStory(myStory)
    }

    const newStory = () => {

        if (!draftId || !currentUser) { return }

        return {
            id: draftId,
            user_id: currentUser.user_id,
            isPublished: false,
            mainSpot: "",
            allSpots: {},
            storySections: [{
                spots: [],
                content: "",
                id: ""
            }]
        } as StoryObject
    }

    const contentMultiline = (text: string) => {
        return text.match(/<\s*(p|h2|h3)(\s+.*?>|>).*?<\s*\/\s*(p|h2|h3)\s*>/ig)
    }

    const contentChanged = (text: any, index: number) => {

        const myStory = story ?? newStory()
        var myText = text

        if (!myStory) { return }

        const lines = contentMultiline(text)

        /** Check if the user has pressed the enter button */
        if (lines && lines.length > 1) {
            myText = lines[0]
            var newSectionText = lines[1]

            const mySection = story.storySections[index]

            if (index == myStory.storySections.length - 1) {
                mySection.content = myText
                myStory.storySections[index] = mySection
                addSection(newSectionText)
                return
            }

            mySection.content = newSectionText
            myStory.storySections[index] = mySection
            insertSectionAtIndex(myStory, index, myText)
            return
        }

        const mySections = myStory.storySections
        const section = mySections[index]
        section.content = myText
        mySections[index] = section

        myStory.storySections = mySections

        updateStory(myStory)
    }

    const updateStory = (myStory: StoryObject) => {
        const storyDuplicate = myStory
        storyDuplicate.updateTime = Math.floor(Date.now() / 1000)
        setStoryUpdated(Math.floor(Date.now() / 1000))
        setStory(storyDuplicate)
    }

    /** Save the draft to the server */
    function saveDraft() {
        if (!draftId || !currentUser || !story) { return }

        if (shouldAutoSave == true) {
            const storyDraft: StoryObject = {
                id: draftId,
                storySections: story.storySections,
                user_id: currentUser.user_id,
                isPublished: story.isPublished,
                title: story.title ?? "",
                mainSpot: story.mainSpot,
                allSpots: story.allSpots ?? {},
                canonicalUrl: canonicalUrl ?? ""
            }

            firebase.firestore().collection(Constants.Firebase.Stories).doc(draftId).set(storyDraft).then(result => {
                setInterval(() => {
                    setIsSaving(false)
                }, 2000)
            })

            console.log("Saving information")
            shouldAutoSave = false
            setIsSaving(true)
        }
    }

    const saveHashtagsToUser = (hashtags: Record<string, boolean>) => {
        let myHashtags = Object.keys(hashtags)

        if (!currentUser) { return }

        myHashtags.map(hashtag => {
            firebase.firestore().collection(Constants.Firebase.UsersCollection).doc(currentUser.user_id).update({
                storyTags: firebase.firestore.FieldValue.arrayUnion(hashtag)
            })
        })
    }

    /** Sets the story to the published state and saves it. Now users can see this story */
    const publishStory = () => {

        if (!draftId || !currentUser || !story?.title) { return }

        var storyId = draftId

        if (story.isPublished == false) {
            storyId = `${Utilities.removeTags(story.title).replace(/[^a-zA-Z ]/g, "").split(' ').join('-')}-${draftId}`
        }

        const hashtags = getHashtagsForStory(story)
        saveHashtagsToUser(hashtags)

        const finishedStory: StoryObject = {
            id: storyId,
            storySections: story.storySections,
            user_id: currentUser.user_id,
            isPublished: true,
            title: story.title,
            mainSpot: story.mainSpot,
            hashtags: hashtags,
            allSpots: story.allSpots ?? {},
            canonicalUrl: canonicalUrl ?? ""
        }

        Utilities.saveHashtagsToFirebase(hashtags)

        firebase.firestore().collection(Constants.Firebase.Stories).doc(storyId).set(finishedStory).then(result => {
            history.push(`/s/${storyId}`)

            if (draftId != storyId) {
                firebase.firestore().collection(Constants.Firebase.Stories).doc(draftId).delete()
            }
        })
    }

    /** Gets an object that represents hashtags for the story */
    const getHashtagsForStory = (story: StoryObject) => {
        let hashtags: Record<string, boolean> = {}

        for (let index = 0; index < story.storySections.length; index++) {
            const section = story.storySections[index]
            const strippedSectionContent = Utilities.removeTags(section.content)
            const sectionHashtags = Utilities.getHashtagObjectFromString(strippedSectionContent)
            hashtags = Object.assign({}, hashtags, sectionHashtags)
        }

        return hashtags
    }


    /** Images were added */
    const spotAdded = (spotId: string, section: StorySection, index: number) => {

        section.spots.push(spotId)
        const myStory = story

        if (!myStory.mainSpot) {
            myStory.mainSpot = spotId
        }

        if (!myStory.allSpots) {
            myStory.allSpots = {}
        }

        myStory.allSpots[spotId] = true
        myStory.storySections[index] = section
        updateStory(myStory)
    }

    const makeSpotMainSpot = (spotId: string) => {
        const myStory = story
        myStory.mainSpot = spotId
        updateStory(myStory)
    }

    /** Insert a new section for a story at the given index
     * @param myIndex The index where inserting the new section
     */
    const insertSectionAtIndex = (currentStory: StoryObject, myIndex: number, newSectionText: string) => {
        const myStory = currentStory

        const myNewStorySection = newStorySection(newSectionText)
        myNewStorySection.content = newSectionText
        myStory.storySections.splice(myIndex, 0, myNewStorySection)
        updateStory(myStory)
    }

    const newStorySection = (text?: string) => {
        const id = uuidv4().substring(0, 8)
        return {
            spots: [],
            content: text ?? "",
            id: id
        }
    }

    const addSection = (text?: string) => {
        const myStory = story
        const id = uuidv4().substring(0, 8)

        myStory.storySections.push({
            spots: [],
            content: text ?? "",
            id: id
        })

        updateStory(myStory)
    }

    const userSelectedSpot = (spotId: string) => {
        if (!addToSection) { return }
        spotAdded(spotId, addToSection.section, selectedSectionIndex)
        setModalState("")
    }

    const closeDialog = () => {
        setModalState("")
        setAddToSection(undefined)
    }

    const removeSpotFromSection = (spotId: string, section: StorySection, index: number) => {
        section.spots = section.spots.filter(s => s != spotId)
        const myStory = story
        myStory.storySections[index] = section

        if (myStory.allSpots && myStory.allSpots[spotId]) {
            delete myStory.allSpots[spotId]
        }

        updateStory(myStory)
    }

    const removeSection = (sectionToDeleteIndex: number) => {
        const myStory = story

        if (myStory.storySections[sectionToDeleteIndex]) {
            myStory.storySections.splice(sectionToDeleteIndex, 1)

        }

        setSectionToDeleteIndex(undefined)
        updateStory(myStory)
    }

    const deleteStory = () => {

        if (!currentUser) { return }

        history.push(`/profile/${currentUser.user_id}`)

        firebase.firestore().collection(Constants.Firebase.Stories).doc(draftId).delete().then(result => {
            Utilities.logData("Deleted the story with id: " + draftId, false)
        }).catch(error => {
            Utilities.logData("Error deleting story with id: " + draftId, true)
        })
    }

    const handleKeyDown = (e: any) => {
        if (e.key.toLowerCase() == 'enter') {

        }
    }

    return (
        <div>
            {
                currentUser &&
                <Modal size="lg" show={modalState == ModalStates.AddSpot} animation={true} centered>
                    <ModalBody>
                        <Button variant="dark" onClick={closeDialog} className="font-weight-bold">X</Button>
                        <SpotCardGrid
                            spots={spots}
                            action={[{
                                text: "+ Add Spot to Story",
                                callback: userSelectedSpot
                            }]
                            } query={firebase.firestore().collection(Constants.Firebase.Tags).where("user_id", '==', currentUser.user_id)} />
                    </ModalBody>
                </Modal>
            }

            {
                currentUser &&
                <Modal size="sm" show={modalState == ModalStates.Delete} animation={true} centered>
                    <ModalBody className="m-3">
                        <div style={{ fontSize: "18px" }} className="mb-3">
                            Are you sure you want to delete this story?
                        </div>
                        <Button variant="danger" onClick={deleteStory} className="rounded-pill font-weight-bold">Yes</Button>
                        <Button variant="dark" onClick={closeDialog} className="rounded-pill ml-2 font-weight-bold">No</Button>
                    </ModalBody>
                </Modal>
            }

            {
                sectionToDeleteIndex &&
                <Modal size="sm" show={sectionToDeleteIndex != undefined} animation={true} centered>
                    <ModalBody>
                        <Col>
                            <span className="font-weight-bold">Are you sure you want to delete this section?</span>
                        </Col>
                        <Col className="mt-3">
                            <Button className="rounded-pill" style={{ backgroundColor: "red", border: "none" }} onClick={() => removeSection(sectionToDeleteIndex)}>Delete Section</Button>
                            <Button className="ml-3 rounded-pill" variant="dark" onClick={() => setSectionToDeleteIndex(undefined)}>Cancel</Button>
                        </Col>
                    </ModalBody>
                </Modal>
            }

            <Modal size="sm" show={modalState == ModalStates.NoSpot} animation={true} centered>
                <ModalBody>
                    <div style={{ fontSize: "18px" }} className="mb-3">
                        Please add at least one spot to this story?
                        </div>

                    <Button variant="dark" onClick={closeDialog} className="rounded-pill font-weight-bold">Okay</Button>
                </ModalBody>
            </Modal>

            <Modal size="lg" show={modalState == ModalStates.CreateSpot} animation={true} centered>
                <ModalBody>
                    <CreateSpot close={closeDialog} spotCreated={userSelectedSpot} />
                </ModalBody>
            </Modal>

            <ShowLogin hideClose={true} show={modalState == ModalStates.SignIn} />
            {
                loaded && !processing &&
                <div>
                    <Container className="mb-5" style={{ maxWidth: Constants.maxWidth }}>
                        <Row>
                            <Col xs="12">
                                <div style={{ height: "50px" }}>
                                    {
                                        isSaving &&
                                        <span>
                                            Saving draft...
                                    </span>
                                    }
                                </div>
                                <div>
                                    {
                                        processing &&
                                        <span>
                                            Publishing...
                                    </span>
                                    }
                                </div>
                                <div>
                                    {
                                        story.isPublished == false &&
                                        <Button variant="dark" className="my-3 rounded-pill" onClick={publishStory}>Publish Story</Button>
                                    }
                                </div>
                            </Col>
                            <Col className="mb-4">
                                <span style={{ fontSize: "20px" }}>A Graffiti Maps Story</span>
                                {
                                    <Button variant="outline-danger" onClick={() => {
                                        setModalState(ModalStates.Delete)
                                    }} className="rounded-pill ml-2">Delete Story</Button>
                                }
                                {
                                    story.isPublished &&
                                    <Button href={`/s/${story.id}`} style={{ border: "none", backgroundColor: "white", color: "black" }}>
                                        View Story
                                    </Button>
                                }
                                <hr />
                            </Col>

                            <Col xs="12">
                                <Editor
                                    style={{ outline: "none", fontSize: "35px" }}
                                    text={story?.title ?? ""}
                                    onChange={handleTitleChange}
                                    options={editorOptions}
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            {
                                story.storySections.map((section, index) => {
                                    return (
                                        <div style={{ width: "100%" }}>
                                            <Col xs="12" className="mt-2">
                                                <Editor
                                                    key={section.id}
                                                    id={section.id}
                                                    // onFocus={() => setSelectedSectionIndex(index) }
                                                    onKeyUp={handleKeyDown}
                                                    style={{ outline: "none", fontSize: "20px" }}
                                                    text={section.content}
                                                    onChange={(text: any, medium: any) => {
                                                        contentChanged(text, index)
                                                    }}
                                                    options={editorOptions}
                                                />
                                            </Col>

                                            <Col xs="12" className="my-3" style={{ maxWidth: "700px", margin: "auto" }}>
                                                <CardDeck>
                                                    {
                                                        section.spots.map((spotId, index) => {
                                                            return (
                                                                <SpotCard key={spotId} highlight={story.mainSpot == spotId} id={spotId} action={[{
                                                                    text: "Remove Spot",
                                                                    callback: (spotId: string) => {
                                                                        console.log(spotId)
                                                                        removeSpotFromSection(spotId, section, index)
                                                                    }
                                                                }, {
                                                                    text: "Set as Main Spot",
                                                                    callback: (spotId: string) => {
                                                                        console.log(spotId)
                                                                        makeSpotMainSpot(spotId)
                                                                    }
                                                                }]
                                                                } />
                                                            )
                                                        })
                                                    }
                                                </CardDeck>
                                            </Col>

                                            {
                                                !Utilities.removeTags(story.storySections[index].content) &&
                                                <Col xs="12" className="mt-4 text-center">
                                                    <Button onClick={() => {
                                                        setAddToSection({
                                                            section: section,
                                                            sectionId: section.id
                                                        })
                                                        setSelectedSectionIndex(index)
                                                        setModalState(ModalStates.CreateSpot)
                                                    }} className="rounded-pill ml-2" variant="outline-dark">+ Create and Add Spot</Button>

                                                    <Button onClick={() => {
                                                        setAddToSection({
                                                            section: section,
                                                            sectionId: section.id
                                                        })
                                                        setSelectedSectionIndex(index)
                                                        setModalState(ModalStates.AddSpot)
                                                    }} className="rounded-pill ml-2" variant="outline-dark">+ Add Spot</Button>

                                                    {
                                                        Object.keys(story.storySections).length > 1 && index != 0 &&
                                                        <Button onClick={() => {
                                                            setSectionToDeleteIndex(index)
                                                        }}
                                                            className="rounded-pill ml-2" variant="outline-danger">Delete Section
                                                        </Button>
                                                    }
                                                </Col>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Row>
                        {
                            <Row className="my-4">
                                <Col xs="12">
                                    <hr />
                                    <Button onClick={() => addSection()} variant="outline-info" className="rounded-pill" style={{ width: "100%" }}>+ Add A Section</Button>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs="12">
                                <SimpleInput
                                    placeholder="Was this article originally posted on another website or blog? If so, enter the URL of the original article."
                                    header={true}
                                    onChange={setCanonicalUrl} />
                            </Col>
                        </Row>


                    </Container>
                </div>

            }

        </div>

    )
}

export default CreateStory