import React, { useState, useEffect, useRef } from 'react'
import SimpleInput from '../form/input'
import { Dropdown, Container, Row, Col, Image, Button } from 'react-bootstrap'
import firebase from 'firebase'
import User from '../../models/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import '../../global.css'
import WallObject from '../wall/wallObject'
import useOnclickOutside from 'react-cool-onclickoutside'
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const SearchInput = (props:any) => {

    const [searchText, setSearchText] = useState<string>("")

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    const [userResults, setUserResults] = useState<User[]>([])

    const [hashtagResults, setHashtagResults] = useState<string[]>([])

    const [wallResults, setWallResults] = useState<WallObject[]>([])

    const [loading, setLoading] = useState(false)

    const [showSearchResults, setShowSearchResults] = useState(false)

    const registerRef = useRef(null)

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })

    const searchResultsStyle = {
        position: "absolute" as "absolute",
        left: isMobile ? "0px" : "30px",
        paddingLeft: "20px",
        width: isMobile ? "100%" : "50%",
        backgroundColor: "white",
        zIndex: 2,
        maxHeight: '350px',
        overflowY: 'auto' as 'auto',
        overflowX: 'hidden' as 'hidden',
    }

    useOnclickOutside(registerRef, () => {
        // When user clicks outside of the component, we can dismiss the search results screen
        setShowSearchResults(false)
    });

    useEffect(() => {

        if (searchText.trim() == "") {
            if (timer) {
                clearTimeout(timer)
            }

            return
        }

        // First time timer will be nil and we want to just return
        if (!timer) {
            setTimer(setTimeout(() => search(searchText.toLowerCase()), 500))
            return
        }

        // Clear the timer so that we don't execute a previous search
        clearTimeout(timer)
        setTimer(setTimeout(() => search(searchText.toLowerCase()), 500))

    }, [searchText])

    /** The component to display the user on the search results screen */
    const User = (props: any) => {
        return (
            <Row className="mt-1 mb-2">
                <Col xs="12">
                    <Image src={props.user.profile_picture_url}
                        className="graffiti-image"
                        style={{
                            width: "30px",
                            height: "30px"
                        }}
                        roundedCircle />
                    <span className="font-weight-bold ml-2">{props.user.username}</span>
                </Col>
            </Row>
        )
    }

    /** The component to display the hashtag results on the search results screen */
    const Hashtag = (props: any) => {
        return (
            <Row>
                <Col xs="11" className="mt-1 mb-2">
                    <Button className="rounded-pill" variant="dark" onClick={() => gotoSearchByHashtagPage(props.hashtag)}>
                        <div className="font-weight-medium">
                            <FontAwesomeIcon icon={faHashtag} color="lightGray" className="mr-2" />
                            {`${props.hashtag}`}
                        </div>
                    </Button>
                </Col>
            </Row>
        )
    }

    const gotoSearchByHashtagPage = (hashtag:string) => {
        props.history.push(`/search/${hashtag}`)        
        setShowSearchResults(false)
    }

    const gotoWall = (wallId: string, wallName: string) => {
        props.history.push(`/wall/${wallId}/${wallName}`)
        setShowSearchResults(false)
    }

    /** The component to display the wall name on the search results screen */
    const Wall = (props: any) => {
        return (
            <Row>
                <Col xs="11" className="mt-1 mb-2">
                    <Button className="color-purplish rounded-pill" onClick={() => gotoWall(props.wallId, props.wallName)}>
                        <div className="font-weight-normal">
                            {`${props.wallName}`}
                        </div>
                    </Button>
                </Col>
            </Row>
        )
    }

    const search = (mySearchText: string) => {

        const end = mySearchText.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1))

        const hashtags = firebase.firestore().collection('hashtags')
            .where('name', '>=', mySearchText)
            .where('name', '<', end)
            .limit(100)
            .get()

        const users = firebase.firestore().collection('users')
            .where('username', '>=', mySearchText)
            .where('username', '<', end)
            .limit(10)
            .get()

        const walls = firebase.firestore().collection('walls')
            .where(`search_name.${mySearchText}`, '==', true)
            .limit(10)
            .get()

        setLoading(true)

        Promise.all([hashtags, users, walls]).then(querySnapshots => {
            let hashtags: string[] = []
            let users: User[] = []
            let walls: WallObject[] = []

            querySnapshots.map((qs, index) => qs.docs.map(document => {
                switch (index) {
                    case 0: //hashtag
                        if (hashtags.indexOf(document.data().name) != -1 || hashtags.length >= 10) { return }
                        hashtags.push(document.data().name)
                        break;
                    case 1:
                        users.push(document.data() as User)
                        break;
                    case 2:
                        let wallObject: WallObject = {} as WallObject
                        wallObject.data = document.data() as any
                        wallObject.id = document.id
                        walls.push(wallObject)
                        break;
                    default:
                        break;
                }
                console.log('Document retrieved: ' + document)
            }))

            setHashtagResults(hashtags)
            setUserResults(users)
            setWallResults(walls)
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }    

    return (
        <div ref={registerRef} style={{ background: 'white' }}>
            <SimpleInput className="d-flex align-items-center" placeholder="Search..." onFocus={() => setShowSearchResults(true)} onChange={setSearchText} />
            {
                searchText.trim() != "" && showSearchResults &&
                
                <div style={searchResultsStyle}>
                    {
                        hashtagResults.length > 0 &&
                        <div>
                            <Row>
                                <Col className="font-weight-bold" xs="12">
                                    Hashtags
                                </Col>
                            </Row>
                            {
                                hashtagResults.map(hashtag => {
                                    return <Hashtag hashtag={hashtag} />
                                })
                            }
                        </div>
                    }
                    {
                        userResults.length > 0 &&
                        <div>
                            <Row>
                                <Col className="font-weight-bold" xs="12">
                                    Users
                                </Col>
                            </Row>
                            {
                                userResults.map(user => {
                                    return <User user={user} />
                                })
                            }
                        </div>
                    }
                    {
                        wallResults.length > 0 &&
                        <div>
                            <Row>
                                <Col className="font-weight-bold" xs="12">
                                    Walls
                                </Col>
                            </Row>
                            {
                                wallResults.map(wall => {
                                    return <Wall wallName={wall.data.name} wallId={wall.id} />
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default withRouter(SearchInput)