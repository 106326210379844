import firebase from 'firebase'

const config = { 
    "type": "service_account",
    "projectId": "graffiti-6cf5a",
    "private_key_id": "fdb2401ffb179e6a35d5085e17a191a0a2dc2a7f",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCUQMptZQFAQO2l\n82RyMARDUj5XGs0iYNAPkWlgCNk4KXoEYTPSd9b2JwVNNuo3/kxm3mSW5lLu+5H0\nueRhkZvCS4VdNcbrV5U3TskyQ5z3glvj605zTYeHsBbw8umnA1rjsq1xJ194OsbV\nb1HxCGUUGYG4PLbndQyy1HFm2zR+gRkdvDCXrfj9p7rKO10oPOa+BYNukdCkKc8W\na37/dKRZK6tC3Ubbef+o/NoK5SEQO2QzC5FOOhEh9Qg51MptOp22Xbh1jW4Db3jf\nO1Yi3E0bHMt1YO99OnDcnH+FjE0eS+DbMG6YSpVGLDuXL+2aZ+Y/72VZyDUa8PYB\njzj+cWaPAgMBAAECggEAAu8oJbOIXrSwrDmqfpLe6rqWFRRCauoR0O6p8Fm/rtuU\nGI6x8pPAbR1gy5WI9WdZLMOj2wa4+nwZK4foWMDQg+JwKjDPyV8+fhpwyfr9M6FG\n+OmMPNgtrmy4r4RaeOpy+8TqdBRftw0p9UlF2Zu206FQaD/I+o2Rad97IecekI7G\n1YLjStg8vk94Du3NC8TMSiClOGxLyBGNMUpRhtIa6hHWrXZnoZFx4MMBAeRwi82z\nLYOZKk3E4RtBOhq+PB+J8SC6EMws3nRoSh9KS7aSnRaaEvRhM1sxbARsvbCDFNqh\nm9/OVhcuNqroGMFAibSOwroAPA4hmV2bzyaNR56hXQKBgQDNRGskbeAyj2kOYXV+\nKKVs1eHjnDcLA4++H7Yk2UKUs9SpXyrxp/hY7Epi6jedwBI5dDvOB4m+2ENeT094\nyzOUGy/HiApYqZ2fyLcEfWzFZLJfpvoi39yXfIN3cAKNIefsRv+1j7p0V6Db36Ht\nW1N3cLsjtEx6PkPChU2Wo6WBJQKBgQC45P8FyDOoDAjMqKrgcfSAdEesUi7+8ts5\nPMAf9XDGuZ5OMJzSS6EF4nneHNHmLwOw/Tj7NV+LQHNeiAXLbrfcu3lqBm5Tz3kB\niqZDCmm47EXqlNHv21H4315BURJ4IfI44p6c1LZba7pfhvlUHU/Tgd1xp2J/BaXO\nEYKetYW8owKBgQCNIORVaKIDwSwm8gT5F3MdUDR97JK/pNQ4kM8HqMSpPrcROFhr\nS+RpJxTBy20vrbYaAHt53R7ssv4monrufy9YPhTsp/ENIpk4GaUTljSpxuEI+OH0\nBlxBXx94h++JH224utvm9gnpIbJ8WdaYPfk2HrDcDIOkbJjj7gj5cExDbQKBgCVl\nM2sw9bmsTW9PQdhJVAFTA1gH+zdXWR13ewg7tQaMbkc0L9OctkGhb7KLs6xRn/aB\nELBGOD/K1kqtSja9+vPq+wCFsnTDBp9YMo5mocFkEDQEy30up6KlUsP1KL4hZ4M3\n2oqvvv44iKZffzgTAZ0uq+Ibz6EPPFy0T+3XmufvAoGBAJ5XYMDgh42npkmPAHU3\nzW3S503876bHRfB1FBiFr9i36CD1rkBpEiEEDj6rbsA6vG+WX1cCik7byMD5PvKQ\n0zB5XWB7k4pcX0dqm6jkiBZ+AJ+F073w5Mymbtn8IjJS6gYSXQpAxvLTRbwIg/hA\npIfEimA1pJZssgTw+qZWIgHu\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-e303i@graffiti-6cf5a.iam.gserviceaccount.com",
    "client_id": "113164288049124095119",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-e303i%40graffiti-6cf5a.iam.gserviceaccount.com",
    "apiKey": "AIzaSyCuVaUSfc9lh4v9pvn4SVIybWH9xolcwj0",
    "authDomain": 'graffiti-6cf5a.firebaseapp.com'
  }

  const configDev = {
    "type": "service_account",
    "projectId": "graffiti-development-77242",
    "private_key_id": "da10d08d7fbdcf7d36bc73e1aa01f871136aa61f",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDbxdfr5BrA/zaY\nrgPHalfzjBl0YvIVma29w3GTQ8jlQ78a2RgBZTaywFuFoQQmJK/xLkVWsdnogeI+\naEYZ08YpqOPl4FzhXg5XXKrt7HSJ3oFv4Hpe0YIdMrqrq2dYghaSXFylX4ZXOH8B\nWU5Urgn8ykC+EA2miyAlfhdkwpnh+xYNOmez1GuAHNQ3nkIbiVr+WgK2NNWm/cbo\nDqa2GmuVNMCbctPBDp3qzPAJR8Csj5wDg0c0ta5lR/yLRPEGeBKnzl0SE5gNSB3l\nN6lbihp0QNBms/qHWekLH3T/eUKzVvR9USK6mEXvsuyAG6Ti6frBZcHDzcMRuXei\nZtp6y4nJAgMBAAECggEAJUQ6JfTrGHjuYBt55pHA0jqagmLY3BLQJFKE6szevwmq\n2Tmg2XO5P7xgiJtSIcmsz//hoB7ObZ/ApoZ2BKElhCDwbCWMembBlczP60JiTJKf\nbkIpXfS21I6qmruY7ag5fZ4N07kMBbQ9zRPAtZlBdk0xh0kfl3MWA2WLP/nDsE7b\nvpU3pcQj6IYkqKOdD7QokvrFZ0tGiAhF7h2oNuYqYB4Aw33w5tFMZHI+QrDJ08s+\nI9v6W3zaEcIjVxw2onsKd2O+2lSoz7zp8qnrdrk+AX1xLoQTrKYOsoEsXxaRmlFN\nikOR/FLi068tipPN3AdNj0m5ehhGNvT0trwEE0G4JQKBgQD+NM0HWufVmhJZgdwu\n8DTO3IV4d9Q9BB0svxrV9ypFoDJGCcav1AetXpdyBXUppnzSP1iOUWx3ogwqdJIx\nH+d9Pzs2ryX2LDjSNhZSnKzVxX+xMacBPnmUZCj5A25khhZBa1kuKHyU7hXUVeav\nc5dwUnBIjjF1qjP0UycOLpnlgwKBgQDdUteFdXiDo4L0LyzO1kDAxDQhhSbNfzjU\nzQZf+K5IIte/5XA8yZHpV+EeKc7YnfjCRvaKXgR2Hu9/9ZUndHOVGV8yr5qDztJN\npuLoeuRBJCizH3W8zFlySWSrM9ZUZouvaty+dh9N++xy0UeVuUmcu8cVjzoF9tM4\nojs9xGC9wwKBgFGFa071hy2ICGRt8ceXvsesDp2QiB69KAIGkzuU1RiZBCZT0kTm\n0UN290hYmbcurg7aw7J4SDmhXRdsZMmeOqV+1qV1y83DZsF7GPOLTR4NBSM1EiKi\n/2hoKn+Y6xkPqyl1nJbh4vn71EJQ/fa8Mg+SUHHkL9ZVK+4pkHfK2A2zAoGAfD1F\nuKhBt3uxRku5Fn7VKKC7Ntnc91xkVail3HfntZzBifpM8AKjAt8PAvSrJZRAESbu\nvKucS4aAIU+Fo/lN7LOqafSDg1w1V9vH4oMh4KNgqXSOjiYUTACBH03hEc3iudz9\n/3iliAxUJfvu+FBjzzTlw+d7xd9RoKgJI1o6iCsCgYEA0UR9UlkGWRbR4GvmlBWz\nZ9W1u4HLbhF/IXdK/mKvpy1YvXhFRZk73OHG0jMbbrvRNqLL01wN4V2Qr2FzsXD4\nfhtYfbyLQyIqPQRRR/PZ/25uaPVcSf7lg4RZBFap+sccVUCipB3MtCcvcvuRDSEV\nQP07Tg/w2GiR6KgeeSBKb1M=\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-m7a8e@graffiti-development-77242.iam.gserviceaccount.com",
    "client_id": "105213849279153356578",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-m7a8e%40graffiti-development-77242.iam.gserviceaccount.com",
    "apiKey": "AIzaSyCi5RviFPb8Z0ZjN08_eGDUkn2Xq-QjOe4",
    "authDomain": 'graffiti-development-77242.firebaseapp.com'
  }
  
  const fire = firebase.initializeApp(config)
  export default fire;